import { call, put, select, takeLatest } from 'redux-saga/effects';
import { selectors as applicationSelectors } from '../../../../../../services/applications/reducers';
import { actions, types } from './reducers';
import api from '../../../../../../services/api/api';

export function* fetchWebhooksInfoRequest(action) {
  try {
    const { payload: vehicleId } = action;

    const applicationId = yield select(applicationSelectors.getSelectedApplication);

    const { data } = yield call(api.fetchWebhooksInfo, applicationId, vehicleId);

    const formattedLogs = {
      webhooksInfo: {
        rows: data.rows,
        columns: data.cols,
      },
    };

    yield put(actions.fetchWebhooksInfoSuccess(formattedLogs));
  } catch (error) {
    yield put(actions.fetchWebhooksInfoFailure(error));
  }
}


export default function* rootSaga() {
  yield takeLatest(types.FETCH_WEBHOOKS_INFO_REQUEST, fetchWebhooksInfoRequest);
}
