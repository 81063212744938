import _ from 'lodash';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { push } from 'react-router-redux';

import api from '../api/api';
import { Toast } from '../../components';
import { types, actions, selectors } from './reducers';
import { selectors as billingSelectors } from '../billing/reducers';
import { actions as errorActions } from '../errorReporter/reducers';
import { actions as secretActions } from '../../components/Credentials/components/Secret/reducers';
import { selectors as authSelectors, actions as authActions } from '../auth/reducers';
import { fetchOrganizations } from '../organizations/sagas';
import { selectors as orgSelectors } from '../organizations/reducers';
import generateAppName from './utils/generateAppName';
import { getOrgApps } from '../../scenes/Application/utils';
import getUrlWithDeepRedirect from './utils/getUrlWithDeepRedirect';

export function* registerApplication(action) {
  try {
    const newApplicationResponse = yield call(api.registerApplication, action.payload);
    const { id: newApplicationId, secret } = newApplicationResponse.data;
    // refresh the list of apps for the organization
    yield call(fetchOrganizations);
    yield put(actions.registerApplicationSuccess(newApplicationId, newApplicationResponse.data));
    yield put(actions.selectApplication(newApplicationId));
    const applications = yield select(selectors.getApplications);
    const planRedirect = yield select(authSelectors.getPlanRedirect);
    // route to configuration if there are existing applications
    // if this is first app, route to overview
    if (_.size(applications) === 1) {
      if (planRedirect) {
        yield put(push('/team/billing'));
      } else {
        yield put(push(`/apps/${newApplicationId}`));
      }
    } else {
      yield put(push(`/apps/${newApplicationId}/configuration`));
    }
    yield put(secretActions.regenerateSecretSuccess(secret));
  } catch (error) {
    yield put(actions.registerApplicationFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* fetchApplications() {
  try {
    // need to propagate error to prevent success action with bad data
    const { organizations, error } = yield call(fetchOrganizations);
    if (error) throw error;

    const organizationIds = Object.keys(organizations);
    const results = yield all(organizationIds.map(id =>
      call(api.fetchOrganizationApplications, id)));

    const orgAccessApps = results.map(result => result.data);

    const formattedApplications = orgAccessApps.reduce((acc, apps) => {
      if (apps) {
        acc.applications = {
          ...acc.applications,
          ...apps.applications,
        };
      }
      return acc;
    }, {});

    yield put(actions.fetchApplicationsSuccess(formattedApplications));
    return formattedApplications;
  } catch (error) {
    yield put(actions.fetchApplicationsFailure(error));
    yield put(errorActions.reportError(error));
    return { error };
  }
}

export function* updateApplication(action) {
  try {
    const updatedApplicationResponse = yield call(
      api.updateApplication,
      action.meta,
      action.payload,
    );
    yield put(actions.updateApplicationSuccess(action.meta, updatedApplicationResponse.data));
  } catch (error) {
    yield put(actions.updateApplicationFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* deleteApplication(action) {
  try {
    yield call(
      api.deleteApplication,
      action.meta,
    );
    yield put(actions.deleteApplicationSuccess(action.meta));
    yield put(push('/team/applications'));
    yield call(Toast, 'Your application was successfully deleted.', 'success');
  } catch (error) {
    yield put(actions.deleteApplicationFailure(action.meta, error));
    yield put(errorActions.reportError(error));
  }
}

// Make sure redux value is updated
export function* updateLockedApplications(action) {
  try {
    const lockedApplications = yield select(selectors.getLockedApplications);
    // If lockedApplications are already identical to payload, return nothing to prevent rerenders
    if (JSON.stringify(action.payload) === JSON.stringify(lockedApplications)) {
      return;
    }
    const updatedLockedApplications = yield call(
      api.updateLockedApplications,
      action.payload,
    );
    yield put(actions.updateLockedApplicationsSuccess(updatedLockedApplications.data));
  } catch (error) {
    yield put(actions.updateLockedApplicationsFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export function* fetchLockedApplications() {
  try {
    const { data } = yield call(api.fetchLockedApplications);
    yield put(actions.fetchLockedApplicationsSuccess(data));
  } catch (error) {
    yield put(actions.fetchLockedApplicationsFailure(error));
    yield put(errorActions.reportError(error));

    // if the error was an authentication error (ex: session timed out) throw error
    // to stop initialize applications from proceeding
    if (error.response && error.response.status === 401) {
      throw error;
    }
  }
}

export function* initializeApplications() {
  try {
    // check for emailToken, if it is available, verify the token
    const emailToken = yield select(authSelectors.getEmailToken);
    const selectedOrganization = yield select(orgSelectors.getSelectedOrganization);
    if (emailToken) {
      yield put(authActions.verifyEmailTokenRequest(emailToken));
    } else {
      // must be a call not a put to wait for result
      yield call(fetchLockedApplications);

      const billingPlan = yield select(billingSelectors.getCurrentPlan);
      if (typeof billingPlan === 'string' && billingPlan.toLowerCase() === 'enterprise') {
        yield call(updateLockedApplications, { payload: [] });
      }

      const selectedApplication = yield select(selectors.getSelectedApplication);
      const lockedApplications = yield select(selectors.getLockedApplications);
      let applications = yield select(selectors.getApplications);
      applications = getOrgApps(applications, selectedOrganization);

      // get deep link to redirect to if present
      const deepRedirect = localStorage.getItem('deep_redirect');

      // if user still has no apps, create one and route to it.
      if (_.size(applications) === 0) {
        /**
         * TODO: this might be the best place to handle the case where
         * a member gets removed from org and has no org of their own.
         * Check if it's possible to have an org without any apps
         */
        // if the user did not create an app name, use default.
        let applicationName = yield select(selectors.getAppName);
        if (!applicationName) {
          applicationName = generateAppName();
        }
        const newApplication = {
          name: applicationName,
        };
        const action = {
          payload: newApplication,
        };

        // must be a call not a put to wait for result
        yield call(registerApplication, action);

        // If user doesn't have a selected app, route to most recently created available app.
      } else {
        let appId;

        if (!_.has(applications, selectedApplication)) {
          // newest available application
          const applicationIds = Object.keys(applications);
          const reverseApplicationIds = applicationIds.reverse();
          const newestApplication = reverseApplicationIds.find(
            id => !lockedApplications.includes(id),
          );
          yield put(actions.selectApplication(newestApplication));
          appId = newestApplication;

        // If user has a selected app in redux, route to it.
        } else {
          appId = selectedApplication;
        }

        let url = `/apps/${appId}`;
        if (deepRedirect) {
          url = getUrlWithDeepRedirect(url, deepRedirect);
          localStorage.removeItem('deep_redirect');
        }

        yield put(push(url));
      }
    }
    yield put(actions.initializeApplicationsSuccess());
  } catch (error) {
    yield put(actions.initializeApplicationsFailure());
    yield put(errorActions.reportError(error));
  }
}

export function* regenerateManagementToken() {
  try {
    const applicationId = yield select(selectors.getSelectedApplication);

    const { data } = yield call(
      api.regenerateManagementToken,
      applicationId,
    );
    yield put(actions.regenerateManagementTokenSuccess(data.token));
  } catch (error) {
    yield put(actions.regenerateManagementTokenFailure(error));
    yield put(errorActions.reportError(error));
  }
}

export default function* rootSaga() {
  yield takeEvery(types.REGISTER_APPLICATION, registerApplication);
  yield takeEvery(types.FETCH_APPLICATIONS, fetchApplications);
  yield takeEvery(types.UPDATE_APPLICATION, updateApplication);
  yield takeEvery(types.DELETE_APPLICATION, deleteApplication);
  yield takeEvery(types.UPDATE_LOCKED_APPLICATIONS, updateLockedApplications);
  yield takeEvery(types.FETCH_LOCKED_APPLICATIONS, fetchLockedApplications);
  yield takeEvery(types.INITIALIZE_APPLICATIONS, initializeApplications);
  yield takeEvery(types.REGENERATE_MANAGEMENT_TOKEN, regenerateManagementToken);
}
