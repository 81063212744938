/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { bool, func, number, object, objectOf, shape, string } from 'prop-types';
import { Button } from '@mui/material';
import _ from 'lodash';

import { staticText, vehicleDetailsFilters } from '../../../../../../../../localization/Application/VehicleManagement/filtersBar';
import { DateRangePicker, FiltersForm, MenuItemWrapper } from '../../../../../../../../components';
import { Form, ResetButton } from '../../../shared/styles';
import getFilterStatusAndTitle from '../../../../../Logs/utils/getFilterStatusAndTitle';

const FiltersBar = ({
  filterValues,
  handleApplyFilters,
  handleResetAll,
  logRestrictions,
  setFormError,
  filtersEnabled,
}) => {
  const [newFormState, setNewFormState] = useState(filterValues);

  const { logDays, minDate, maxDate } = logRestrictions;
  const dateRange = [newFormState.start, newFormState.end];

  const handleSubmit = (e) => {
    e.preventDefault();
    handleApplyFilters(newFormState);
    reportToSegment(
      types.TRACK,
      eventNames.formSubmitted,
      {
        label: '[vehicle details] filter values',
        form_content: newFormState,
      },
    );
  };

  const handleResetClick = () => {
    setFormError('');
    handleResetAll();
    setNewFormState(filterValues);
    reportToSegment(
      types.TRACK,
      eventNames.buttonClicked,
      {
        label: 'reset',
        text: `[vehicle details] ${staticText.resetAll}`,
      },
    );
  };
  const getHandleChange = filterKey => (newValue) => {
    if (filterKey === 'dateRange') {
      const [startDate, endDate] = newValue;
      setNewFormState({
        ...newFormState,
        start: startDate,
        end: endDate,
      });
    } else {
      setNewFormState({
        ...newFormState,
        [filterKey]: newValue,
      });
    }
    setFormError('');
  };

  useEffect(() => {
    // because filterValues can be changed elsewhere
    setNewFormState(filterValues);
  }, [filterValues]);

  return (
    <Form
      onSubmit={handleSubmit}
      sx={{
        justifyContent: 'flex-end',
      }}
    >
      <ResetButton
        id="reset-all-button"
        onClick={handleResetClick}
        variant="text"
      >
        {staticText.resetAll}
      </ResetButton>
      <DateRangePicker
        darkerBorder
        label={staticText.dateRange}
        minDate={minDate}
        maxDate={maxDate}
        onAccept={getHandleChange('dateRange')}
        value={dateRange}
        popupMessage={staticText.logDaysAllowance(logDays)}
      />
      {filtersEnabled && vehicleDetailsFilters.map(({
        filterKey, icon, formTitle, id, minWidth, columns,
      }) => {
        const {
          isFilterActive,
          menuItemWrapperTitle,
        } = getFilterStatusAndTitle(newFormState, filterKey, formTitle);

        return (
          <MenuItemWrapper
            title={menuItemWrapperTitle}
            isFilterActive={isFilterActive}
            key={filterKey}
            Icon={icon}
            id={id}
            minWidth={minWidth}
            filterKey={filterKey}
          >
            <FiltersForm
              checkboxOptions={newFormState[filterKey]}
              updateCheckboxes={getHandleChange(filterKey)}
              columns={columns}
              title={formTitle}
            />
          </MenuItemWrapper>
        );
      })}
      <Button
        color="primary"
        disabled={_.isEqual(newFormState, filterValues)}
        type="submit"
        variant="contained"
      >
        {staticText.apply}
      </Button>
    </Form>
  );
};

export default FiltersBar;

FiltersBar.propTypes = {
  filterValues: shape({
    start: object,
    end: object,
    errorTypes: objectOf(bool),
    requestMethods: objectOf(bool),
  }).isRequired,
  handleApplyFilters: func.isRequired,
  handleResetAll: func.isRequired,
  logRestrictions: shape({
    minDate: string.isRequired,
    maxDate: string.isRequired,
    logDays: number.isRequired,
  }).isRequired,
  setFormError: func.isRequired,
  filtersEnabled: bool.isRequired,
};
