import { Box } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { arrayOf, func, number, object, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { DemoLink } from '../../../../components';
import { topbarNav } from '../../../../localization/Application/nav';
import { capitalizeFirstLetter } from '../../utils';
import { HoverText } from '../SidebarNav/styles';
import { AccountMenu, AppSelector, OrgSelector, SupportModal } from './components';
import { Divider, Logo, Nav, TopNavLinks, WarningDot } from './styles';

const TopbarNav = ({
  actions: {
    logoutRequest,
    selectOrganization,
  },
  organizations,
  organization,
  pathname,
  orgApps,
  selectedApplication,
  userContext,
  billingInfo,
  view,
}) => {
  const application = orgApps[selectedApplication];

  const [modalView, setModalView] = useState(null);

  const hideAppSelector = view !== 'apps';
  const { settings, docs, support } = topbarNav;

  const userDisplayName = `${userContext.firstName} ${userContext.lastName}`;

  const handleLinkClick = (e, path, text) => {
    reportToSegment(types.TRACK, eventNames.linkClicked, { text, path });
  };

  const handleSupportClick = () => {
    setModalView('contactSupport');
    reportToSegment(types.TRACK, eventNames.modalOpened, {
      label: support.text,
      title: support.text,
    });
  };

  const billingWarning = Boolean(billingInfo.vehiclesOverLimit) ||
    Boolean(billingInfo.numOfApiViolationVehicles) ||
    Boolean(billingInfo.delinquent);

  return (
    <React.Fragment>
      <Nav aria-label="primary">
        <Box display="flex">
          <Logo href="/" onClick={e => handleLinkClick(e, '/', 'dashboard home')}>
            <img src="/assets/icons/nav/steeringWheel.svg" alt="Smartcar Dashboard" />
          </Logo>
          <Divider />
          <Box display="flex">
            <Box display="flex" alignItems="center">
              <OrgSelector
                organization={organization}
                organizations={organizations}
                selectOrganization={selectOrganization}
              />
            </Box>
            {!hideAppSelector && (
              <React.Fragment>
                <Divider />
                <AppSelector
                  organization={organization}
                  pathname={pathname}
                  orgApps={orgApps}
                  applicationName={application.name}
                  applicationId={application.id}
                  applicationLogoUrl={application.logoUrl}
                />
              </React.Fragment>
            )}
          </Box>
        </Box>
        <TopNavLinks>
          <DemoLink
            dashboardUserId={userContext.dashboardUserId}
            organizationId={organization.id}
          />
          <li>
            <Link
              to={settings.path}
              onClick={e => handleLinkClick(e, settings.path, settings.text)}
            >
              <img src={settings.iconSrc} alt={settings.text} />
              <HoverText className="hover-text" location="topbar">
                {capitalizeFirstLetter(settings.text)}
              </HoverText>
              {billingWarning && view === 'apps' && <WarningDot />}
            </Link>
          </li>
          <li>
            <a
              href={docs.path}
              target="_blank"
              rel="noreferrer"
              title={capitalizeFirstLetter(docs.text)}
              onClick={e => handleLinkClick(e, docs.path, docs.text)}
            >
              <img src={docs.iconSrc} alt={docs.text} />
              <HoverText className="hover-text" location="topbar">{capitalizeFirstLetter(docs.text)}</HoverText>
            </a>
          </li>
          <li>
            <button type="button" onClick={handleSupportClick}>
              <img src={support.iconSrc} alt={support.text} />
              <HoverText className="hover-text" location="topbar">{capitalizeFirstLetter(support.text)}</HoverText>
            </button>
          </li>
          <Divider />
          <AccountMenu
            userDisplayName={userDisplayName}
            userEmail={userContext.email}
            logoutRequest={logoutRequest}
            setModalView={setModalView}
          />
        </TopNavLinks>
      </Nav>
      {modalView && modalView === 'contactSupport' && (
        <SupportModal toggleModal={setModalView} />
      )}
    </React.Fragment>
  );
};

export default TopbarNav;

TopbarNav.propTypes = {
  actions: shape({
    logoutRequest: func.isRequired,
  }).isRequired,
  organization: shape({
    id: string.isRequired,
    name: string.isRequired,
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  organizations: arrayOf(object).isRequired,
  selectedApplication: string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  orgApps: object.isRequired,
  pathname: string.isRequired,
  userContext: shape({
    firstName: string.isRequired,
    lastName: string.isRequired,
  }).isRequired,
  billingInfo: shape({
    vehiclesOverLimit: number.isRequired,
    numOfApiViolationVehicles: number,
  }).isRequired,
  view: string.isRequired,
};
