/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import {
  any,
  object,
  oneOfType,
  bool,
  array,
  number,
  func,
  shape,
  string,
} from 'prop-types';
import { Typography } from '@mui/material';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import moment from 'moment-timezone';

import { Feedback, InlineLinks, LogTable, Spinner } from '../../../../../../components';
import { menuItems } from '../../../../../../localization/Application/Logs/logsTable';
import { staticText } from '../../../../../../localization/Application/Logs/filtersBar';

import { ErrorsList, FiltersContainer, LogsContainer } from './styles';
import { PAGE_SIZE, initialState } from '../../reducers';
import NoResultsFound from './components/NoResultsFound';
import formatFilterValues from '../../utils/formatFilterValues';
import { LogDetailsDrawer } from './components';
import FiltersBar from './components/FiltersBar';
import validateFilterValues from '../../utils/validateFilterValues';
import { resetMakesFilterValues, updateMakesFilterValues, updateStatusCodesFilterValues } from '../../utils/updateFilterValues';
import { useDelay } from '../../../../../../hooks';
import { exceededStartLimit, getLogRestrictions } from '../../../../utils';
import FEATURES from '../../../../../../services/featureGate/features';
import { gatedFeatureData } from '../../../../../../services/featureGate';

const RequestLogs = ({
  actions: {
    fetchRequestLogs,
    fetchAllCompatibleVehicles,
    updateFilterValues,
  },
  pageInfo,
  logErrors,
  setCurrentModal,
  isFetchingRequestLogs,
  isFetchingInitialRequestLogs,
  filterValues,
  paginationModel,
  compatibleMakes,
  featureSetId,
  selectedChartOption,
}) => {
  const [formError, setFormError] = useState('');
  const { afterDelay: showLoading, resetTimer } = useDelay();

  const logRestrictions = getLogRestrictions(featureSetId, FEATURES.LOG_DAYS);
  const filtersEnabled = !gatedFeatureData(FEATURES.LOG_FILTER, featureSetId);
  const { logDays, maxDate, defaultStartDate } = logRestrictions;

  const hasExceededStartLimit = exceededStartLimit(filterValues.start);

  const getPageInfo = (page, filters) => {
    const formattedFilterValues = formatFilterValues(
      filters,
      selectedChartOption.keepSelectedChartMakes,
    );

    fetchRequestLogs({
      newPaginationModel: {
        page,
        pageSize: PAGE_SIZE,
      },
      filterValues: {
        ...formattedFilterValues,
        offset: page * PAGE_SIZE,
      },
    });
  };

  const handlePaginationModelChange = (newPaginationModel) => {
    getPageInfo(newPaginationModel.page, filterValues);

    const [label, text] =
      newPaginationModel.page > paginationModel.page
        ? ['forward', '[logs - right arrow icon]']
        : ['back', '[logs - left arrow icon]'];
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label, text });
    resetTimer();
  };

  const handleResetAll = () => {
    const defaultFilterValues = {
      ...initialState.filterValues,
      makes: resetMakesFilterValues(compatibleMakes),
      start: moment(defaultStartDate).utc(),
      end: moment(maxDate).utc(),
    };

    getPageInfo(0, defaultFilterValues);
    updateFilterValues(defaultFilterValues);
    setFormError('');

    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'reset',
      text: `[logs] ${staticText.resetAll}`,
    });
    resetTimer();
  };

  /* istanbul ignore next */
  const requestLogsErrorsToDisplay = Object.entries(logErrors).filter(([errorKey, message]) => {
    if (!message) return false;
    if (['requestLogs', 'makes', 'statusCodes', 'exportData'].includes(errorKey)) return true;
    return false;
  });
  /* istanbul ignore next */
  if (formError) {
    requestLogsErrorsToDisplay.push(['formError', formError]);
  }

  const handleApplyFilters = (newFilterValues) => {
    const validated = validateFilterValues(newFilterValues, logRestrictions);
    /* istanbul ignore next */
    if (validated.error) {
      setFormError(validated.error);
      return;
    }

    getPageInfo(0, newFilterValues);
    updateFilterValues(newFilterValues);
    setFormError('');
    resetTimer();
  };

  const moreActions = {
    menuItems,
    actionMap: {
      viewLogs: (rowItem) => {
        setCurrentModal(
          <LogDetailsDrawer
            closeModal={() => setCurrentModal(null)}
            log={rowItem}
          />,
        );
      },
    },
  };

  // fetches requestLogs and compatibleVehicles once on initialization
  useEffect(() => {
    const initializedFilterValues = {
      ...filterValues,
      // if a make is selected from the makes overview charts, then update filterValues
      makes: !selectedChartOption.makes ?
        filterValues.makes : updateMakesFilterValues(selectedChartOption.makes),
      // if a statusCode is selected from the statusCodes overview charts, then update filterValues
      statusCodes: !selectedChartOption.statusCodes ?
        filterValues.statusCodes :
        updateStatusCodesFilterValues(filterValues.statusCodes, selectedChartOption.statusCodes),
      start: filterValues.start ? filterValues.start : moment(defaultStartDate).utc(),
      end: filterValues.end ? filterValues.end : moment(maxDate).utc(),
    };

    getPageInfo(0, initializedFilterValues);
    updateFilterValues(initializedFilterValues);
    fetchAllCompatibleVehicles();
  }, []);

  return (
    <LogsContainer>
      {
        hasExceededStartLimit && logDays > 90 &&
        <Feedback type="info" message={<InlineLinks text={staticText.exceededSmartcarStartLimit} />} />
      }
      <FiltersContainer>
        <FiltersBar
          handleResetAll={handleResetAll}
          filterValues={filterValues}
          handleApplyFilters={handleApplyFilters}
          logRestrictions={logRestrictions}
          filtersEnabled={filtersEnabled}
        />
      </FiltersContainer>
      {requestLogsErrorsToDisplay.length > 0 && (
        <ErrorsList>
          {requestLogsErrorsToDisplay.map(([errorKey, message]) => {
            return (
              <li key={errorKey}>
                <Typography variant="caption" color="error">
                  {message}
                </Typography>
              </li>
            );
          })}
        </ErrorsList>
      )}
      {isFetchingInitialRequestLogs && <Spinner delay={200} />}
      {!isFetchingInitialRequestLogs &&
        pageInfo.rows &&
        pageInfo.rows.length > 0 && (
          <LogTable
            pageInfo={pageInfo}
            pageSizeOptions={[PAGE_SIZE]}
            paginationModel={paginationModel}
            handlePaginationModelChange={handlePaginationModelChange}
            loading={isFetchingRequestLogs && showLoading}
            moreActions={moreActions}
            rowCount={pageInfo.rowCount}
          />
        )}
      {!isFetchingInitialRequestLogs && pageInfo.rows && pageInfo.rows.length === 0 &&
        <NoResultsFound resetFilters={handleResetAll} />
      }
    </LogsContainer>
  );
};

export default RequestLogs;

RequestLogs.propTypes = {
  actions: shape({
    fetchRequestLogs: func.isRequired,
    fetchAllCompatibleVehicles: func.isRequired,
    updateFilterValues: func.isRequired,
  }).isRequired,
  isFetchingInitialRequestLogs: bool.isRequired,
  isFetchingRequestLogs: bool.isRequired,
  filterValues: shape({
    offset: number.isRequired,
    end: any,
    start: any,
    limit: number.isRequired,
  }).isRequired,
  pageInfo: oneOfType([
    shape({
      rows: array.isRequired,
      columns: array.isRequired,
      rowCount: number.isRequired,
    }),
    shape({}),
  ]).isRequired,
  paginationModel: shape({
    page: number,
    pageSize: number,
  }).isRequired,
  logErrors: shape({
    requestLogs: string,
    makes: string,
    statusCodes: string,
    exportData: string,
    logDrawerVehicleDetails: string,
  }).isRequired,
  compatibleMakes: object.isRequired,
  setCurrentModal: func.isRequired,
  featureSetId: string.isRequired,
  selectedChartOption: shape({
    makes: string,
    statusCodes: string,
    keepSelectedChartMakes: bool,
  }).isRequired,
};
