/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { array, bool, func, number, object, objectOf, oneOfType, shape, string } from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import validator from 'validator';
import _ from 'lodash';
import moment from 'moment-timezone';

import ApplicationHeader from '../../../ApplicationHeader';
import staticText, { errors } from '../../../../../../localization/Application/VehicleManagement/vehicleManagement';
import { staticText as logsText } from '../../../../../../localization/Application/Logs/filtersBar';
import vehicleDetailsText from '../../../../../../localization/Application/VehicleManagement/vehicleDetails';
import { Section, TableContainer } from './styles';
import { FiltersBar, NoResultsFound, VehicleOverview } from './components';
import { PAGE_SIZE, initialState } from './reducers';
import { status } from '../../reducers';
import { ErrorsList, FiltersContainer } from '../shared/styles';
import formatFilterValues from './utils/formatFilterValues';
import validateFilterValues from './utils/validateFilterValues';
import { Feedback, InlineLinks, LogTable, NoResults, Spinner } from '../../../../../../components';
import { menuItems } from '../../../../../../localization/Application/Logs/logsTable';
import { exceededStartLimit, getLogRestrictions } from '../../../../utils';
import FEATURES from '../../../../../../services/featureGate/features';
import { useDelay } from '../../../../../../hooks';
import { gatedFeatureData } from '../../../../../../services/featureGate';

const VehicleDetails = ({
  actions: {
    fetchVehicleInfo,
    fetchVehicleLogs,
    fetchVehicleOverview,
    resetVehicleDetailsState,
    updateDisconnectStatus,
    updateFilterValues,
  },
  applicationId,
  match,
  openDisconnectModal,
  openLogDrawer,
  pageInfo,
  paginationModel,
  disconnectStatus,
  isFetchingInitialLogs,
  isFetchingVehicleInfo,
  isFetchingVehicleLogs,
  isFetchingVehicleOverview,
  filterValues,
  featureSetId,
  overview,
  vehicleLogsErrors,
}) => {
  const [formError, setFormError] = useState('');
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const { afterDelay: showLoading, resetTimer } = useDelay();

  const hasExceededStartLimit = exceededStartLimit(filterValues.start);

  const { vehicleId } = match.params;
  if (!validator.isUUID(vehicleId)) {
    /* istanbul ignore next */
    return <Redirect to={`/apps/${applicationId}/vehicles`} />;
  }

  const { make, model, year } = overview;
  const ymmHeading = (make && model && year) ? `${year} ${make} ${model}` : '';

  const filtersEnabled = !gatedFeatureData(FEATURES.LOG_FILTER, featureSetId);

  const logRestrictions = getLogRestrictions(featureSetId, FEATURES.LOG_DAYS);
  const { logDays, maxDate, defaultStartDate } = logRestrictions;
  const initialFiltersWithDateRestrictions = {
    ...initialState.filterValues,
    start: moment(defaultStartDate).utc(),
    end: moment(maxDate).utc(),
  };
  const defaultPagination = initialState.paginationModel;

  const getPageInfo = (page, filters) => {
    const formattedFilterValues = {
      ...formatFilterValues(filters),
      offset: page * PAGE_SIZE,
      limit: PAGE_SIZE,
    };

    fetchVehicleLogs({
      vehicleId,
      filterValues: formattedFilterValues,
      newPaginationModel: {
        page,
        pageSize: PAGE_SIZE,
      },
    });
  };

  const handlePaginationModelChange = (newPaginationModel) => {
    getPageInfo(newPaginationModel.page, filterValues);
    const [label, text] = newPaginationModel.page > paginationModel.page ?
      ['forward', '[vehicleDetails - right arrow icon]'] : ['back', '[vehicleDetails - left arrow icon]'];
    reportToSegment(types.TRACK, eventNames.buttonClicked, { label, text });
    resetTimer();
  };

  const handleResetAll = () => {
    const defaultFilterValues = {
      ...initialState.filterValues,
      start: moment(defaultStartDate).utc(),
      end: moment(maxDate).utc(),
    };
    getPageInfo(defaultPagination.page, defaultFilterValues);
    updateFilterValues(defaultFilterValues);
    resetTimer();
  };

  const handleApplyFilters = (newFilterValues) => {
    const validated = validateFilterValues(newFilterValues, logRestrictions);
    if (validated.error) {
      setFormError(validated.error);
      return;
    }

    setFormError('');
    getPageInfo(defaultPagination.page, newFilterValues);
    updateFilterValues(newFilterValues);
    resetTimer();
  };

  const overviewSectionErrors = Object.entries(vehicleLogsErrors)
    .filter(([errorKey, message]) => {
      if (!message) return false;
      if (['info', 'overview'].includes(errorKey)) return true;
      return false;
    });
  const logSectionErrors = Object.entries(vehicleLogsErrors)
    .filter(([errorKey, message]) => {
      if (!message) return false;
      if (['logs'].includes(errorKey)) return true;
      return false;
    });
  if (formError) {
    logSectionErrors.push(['formError', formError]);
  }

  const moreActions = {
    menuItems,
    actionMap: {
      viewLogs: (rowItem) => {
        openLogDrawer({
          vehicleId,
          ...rowItem,
        });
      },
    },
  };

  const shouldRender = (componentKey) => {
    const renderConditions = {
      logErrors: logSectionErrors.length > 0,
      spinner: isFetchingInitialLogs
        || (isFetchingVehicleLogs && pageInfo.rows && pageInfo.rows.length === 0),
      logTable: !isFetchingInitialLogs && pageInfo.rows && pageInfo.rows.length > 0,
      noResults: !isFetchingInitialLogs
        && !isFetchingVehicleLogs
        && pageInfo.rows
        && pageInfo.rows.length === 0,
    };
    return renderConditions[componentKey];
  };

  const renderComponents = () => {
    if (shouldRedirect) {
      /* istanbul ignore next */
      return <Redirect to={`/apps/${applicationId}/vehicles`} />;
    }

    if (vehicleLogsErrors.info === errors.vehicleNotFound) {
      return (
        <React.Fragment>
          <ApplicationHeader
            backLink={{
              path: `/apps/${applicationId}/vehicles`,
              text: staticText.navigation.allVehicles,
            }}
            heading={staticText.vehicleNotFoundHeader}
          />
          <NoResults text={errors.vehicleNotFound} />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <ApplicationHeader
          backLink={{
            path: `/apps/${applicationId}/vehicles`,
            text: staticText.navigation.allVehicles,
          }}
          heading={ymmHeading}
          cta={{
            text: vehicleDetailsText.disconnectVehicle,
            onclick: () => openDisconnectModal(overview),
            disconnectIcon: true,
          }}
          dashboardPermission="read_vehicle_management"
        />
        <Section minHeight="217px">
          <VehicleOverview
            info={overview}
            loading={showLoading && (isFetchingVehicleOverview || isFetchingVehicleInfo)}
            errors={overviewSectionErrors}
          />
        </Section>
        <Section minHeight="863px">
          <Typography variant="h2" mb={2}>{vehicleDetailsText.tableHeader}</Typography>
          {
            hasExceededStartLimit && logDays > 90 &&
            <div>
              <Feedback type="info" message={<InlineLinks text={logsText.exceededSmartcarStartLimit} />} />
            </div>
          }
          <FiltersContainer>
            <FiltersBar
              filterValues={filterValues}
              handleApplyFilters={handleApplyFilters}
              handleResetAll={handleResetAll}
              logRestrictions={logRestrictions}
              setFormError={setFormError}
              filtersEnabled={filtersEnabled}
            />
          </FiltersContainer>
          {shouldRender('logErrors') &&
            <ErrorsList alignItems="flex-end">
              {logSectionErrors.map(([errorKey, message]) => {
                return (
                  <li key={errorKey}>
                    <Typography variant="caption" color="error">
                      {message}
                    </Typography>
                  </li>
                );
              })}
            </ErrorsList>
          }
          {shouldRender('spinner') &&
            <Spinner additionalClassNames="vehicle-details" delay={200} />
          }
          {shouldRender('logTable') &&
            <TableContainer>
              <LogTable
                pageInfo={pageInfo}
                pageSizeOptions={[PAGE_SIZE]}
                paginationModel={paginationModel}
                handlePaginationModelChange={handlePaginationModelChange}
                loading={isFetchingVehicleLogs && showLoading}
                moreActions={moreActions}
              />
            </TableContainer>
          }
          {shouldRender('noResults') &&
            <NoResultsFound
              provideLearnMoreCta={
                _.isEqual(initialFiltersWithDateRestrictions, filterValues)
              }
              resetFilters={handleResetAll}
            />
          }
        </Section>
      </React.Fragment>
    );
  };

  useEffect(() => {
    const initializedFilterValues = {
      ...filterValues,
      start: filterValues.start ? filterValues.start : moment(defaultStartDate).utc(),
      end: filterValues.end ? filterValues.end : moment(maxDate).utc(),
    };
    getPageInfo(paginationModel.page, initializedFilterValues);
    updateFilterValues(initializedFilterValues);
    fetchVehicleOverview(vehicleId);
    fetchVehicleInfo(vehicleId);
    reportToSegment(types.PAGE, 'Vehicles - Vehicle Details');

    return () => {
      resetVehicleDetailsState();
    };
  }, []);

  useEffect(() => {
    /* istanbul ignore next */
    if (disconnectStatus === status.SUCCESS) {
      setShouldRedirect(true);
      updateDisconnectStatus(status.INACTIVE);
    } else if (disconnectStatus === status.FAILURE) {
      updateDisconnectStatus(status.INACTIVE);
    }
  }, [disconnectStatus]);

  return (
    renderComponents()
  );
};

export default withRouter(VehicleDetails);

VehicleDetails.propTypes = {
  actions: shape({
    fetchVehicleInfo: func.isRequired,
    fetchVehicleLogs: func.isRequired,
    fetchVehicleOverview: func.isRequired,
    resetVehicleDetailsState: func.isRequired,
    updateDisconnectStatus: func.isRequired,
    updateFilterValues: func.isRequired,
  }).isRequired,
  applicationId: string.isRequired,
  match: shape({
    params: shape({
      vehicleId: string.isRequired,
    }),
  }).isRequired,
  openDisconnectModal: func.isRequired,
  openLogDrawer: func.isRequired,
  pageInfo: shape({
    rows: array,
    columns: array,
    rowCount: number,
  }).isRequired,
  paginationModel: shape({
    page: number,
    pageSize: number,
  }).isRequired,
  disconnectStatus: string.isRequired,
  isFetchingInitialLogs: bool.isRequired,
  isFetchingVehicleInfo: bool.isRequired,
  isFetchingVehicleLogs: bool.isRequired,
  isFetchingVehicleOverview: bool.isRequired,
  filterValues: shape({
    start: object,
    end: object,
    errorType: objectOf(bool),
    requestMethod: objectOf(bool),
  }).isRequired,
  featureSetId: string.isRequired,
  overview: shape({
    make: string,
    model: string,
    year: number,
    userId: string,
    vehicleId: string,
    connectedAt: string,
    apiCallLimit: number,
    billingPeriodEndDate: oneOfType([string, number]),
    requestCount: number,
  }).isRequired,
  vehicleLogsErrors: shape({
    info: string,
    logs: string,
    overview: string,
  }).isRequired,
};
