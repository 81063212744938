import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ConnectedVehicles from './ConnectedVehicles';
import { actions } from '../../reducers';
import { actions as vehicleActions } from '../VehicleDetails/reducers';
import { selectors as orgSelectors } from '../../../../../../services/organizations/reducers';

function mapStateToProps(state) {
  return {
    applicationId: state.applications.selectedApplication,
    ...state.vehicles,
    featureSetId: orgSelectors.getCurrentOrganization(state).featureSetId,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    fetchAllCompatibleVehicles,
    fetchConnectedVehicles,
    updateDisconnectStatus,
    updateFilterValues,
  } = actions;

  const {
    updateOverview,
  } = vehicleActions;

  return {
    actions: bindActionCreators(
      {
        fetchAllCompatibleVehicles,
        fetchConnectedVehicles,
        updateDisconnectStatus,
        updateFilterValues,
        updateOverview,
      },
      dispatch,
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedVehicles);
