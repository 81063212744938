const FEATURES = {
  VEHICLE_SIMULATOR: 'vehicleSimulator',
  SCHEDULED_WEBHOOKS: 'scheduledWebhooks',
  EVENT_WEBHOOKS: 'eventWebhooks',
  LOG_DAYS: 'logDays',
  LOG_EXPORT: 'logExport',
  LOG_FILTER: 'logFilter',
  APPLICATIONS: 'applications',
  CHAT_SUPPORT: 'chatSupport',
  CONNECT_CONFIG: 'connectConfig',
  TEAMS: 'teams',
  TEAMS_ROLES: 'teamsRoles',
  CONNECT_INSIGHTS_DAYS: 'connectInsightsDays',
  CONNECT_FUNNEL: 'connectFunnel',
  BRAND_SELECT: 'brandSelect',
  SINGLE_SELECT: 'singleSelect',
};

export default FEATURES;

