import _ from 'lodash';

import staticText from '../../../../localization/Application/connect-config';
import { selectors as appSelectors } from '../../../../services/applications/reducers';

export const types = {
  FETCH_SUPPORTED_COUNTRIES_REQUEST: 'developer-client/connect-config/FETCH_SUPPORTED_COUNTRIES_REQUEST',
  FETCH_SUPPORTED_COUNTRIES_SUCCESS: 'developer-client/connect-config/FETCH_SUPPORTED_COUNTRIES_SUCCESS',
  FETCH_SUPPORTED_COUNTRIES_FAILURE: 'developer-client/connect-config/FETCH_SUPPORTED_COUNTRIES_FAILURE',

  FETCH_CONNECT_CONFIG_REQUEST: 'developer-client/connect-config/FETCH_CONNECT_CONFIG_REQUEST',
  FETCH_CONNECT_CONFIG_SUCCESS: 'developer-client/connect-config/FETCH_CONNECT_CONFIG_SUCCESS',
  FETCH_CONNECT_CONFIG_FAILURE: 'developer-client/connect-config/FETCH_CONNECT_CONFIG_FAILURE',

  FETCH_MAKES_BY_COUNTRY_REQUEST: 'developer-client/connect-config/FETCH_MAKES_BY_COUNTRY_REQUEST',
  FETCH_MAKES_BY_COUNTRY_SUCCESS: 'developer-client/connect-config/FETCH_MAKES_BY_COUNTRY_SUCCESS',
  FETCH_MAKES_BY_COUNTRY_FAILURE: 'developer-client/connect-config/FETCH_MAKES_BY_COUNTRY_FAILURE',

  FETCH_BRAND_MANAGEMENT_FILTER_OPTIONS: 'developer-client/connect-config/FETCH_BRAND_MANAGEMENT_FILTER_OPTIONS',
  FETCH_BRAND_MANAGEMENT_FILTER_OPTIONS_SUCCESS: 'developer-client/connect-config/FETCH_BRAND_MANAGEMENT_FILTER_OPTIONS_SUCCESS',
  FETCH_BRAND_MANAGEMENT_FILTER_OPTIONS_FAILURE: 'developer-client/connect-config/FETCH_BRAND_MANAGEMENT_FILTER_OPTIONS_FAILURE',

  FETCH_APPLICATION_MAKES_REQUEST: 'developer-client/connect-config/FETCH_APPLICATION_MAKES_REQUEST',
  FETCH_APPLICATION_MAKES_SUCCESS: 'developer-client/connect-config/FETCH_APPLICATION_MAKES_SUCCESS',
  FETCH_APPLICATION_MAKES_FAILURE: 'developer-client/connect-config/FETCH_APPLICATION_MAKES_FAILURE',

  UPDATE_SELECTED_MAKES: 'developer-client/connect-config/UPDATE_SELECTED_MAKES',
  UPDATE_AVAILABLE_MAKES_AFTER_APPLIED_FILTER: 'developer-client/connect-config/UPDATE_AVAILABLE_MAKES_AFTER_APPLIED_FILTER',
  UPDATE_SELECTED_COUNTRIES: 'developer-client/connect-config/UPDATE_SELECTED_COUNTRIES',
  UPDATE_APPLIED_FILTERS: 'developer-client/connect-config/UPDATE_APPLIED_FILTERS',
  DISABLE_ALL_BRANDS: 'developer-client/connect-config/DISABLE_ALL_BRANDS',
  UPDATE_PREVIEW_SETTINGS: 'developer-client/connect-config/UPDATE_PREVIEW_SETTINGS',
  CLEAR_ERROR: 'developer-client/connect-config/CLEAR_ERROR',

  UPDATE_CONNECT_CONFIG_REQUEST: 'developer-client/connect-config/UPDATE_CONNECT_CONFIG_REQUEST',
  UPDATE_CONNECT_CONFIG_SUCCESS: 'developer-client/connect-config/UPDATE_CONNECT_CONFIG_SUCCESS',
  UPDATE_CONNECT_CONFIG_FAILURE: 'developer-client/connect-config/UPDATE_CONNECT_CONFIG_FAILURE',

  UPDATE_APP_LOGO_REQUEST: 'developer-client/connect-config/UPDATE_APP_LOGO_REQUEST',
  UPDATE_APP_LOGO_SUCCESS: 'developer-client/connect-config/UPDATE_APP_LOGO_SUCCESS',
  UPDATE_APP_LOGO_FAILURE: 'developer-client/connect-config/UPDATE_APP_LOGO_FAILURE',

  DELETE_APP_LOGO: 'developer-client/connect-config/DELETE_APP_LOGO',

  UPDATE_CURRENT_TAB: 'developer-client/connect-config/UPDATE_CURRENT_TAB',
};

export const initialState = {
  applicationMakes: [],
  supportedCountries: [],
  brandManagementCapabilities: {},
  connectConfig: null,
  fetchSupportedCountriesError: '',
  fetchingConnectConfigErrors: {
    applicationMakes: '',
    connectConfig: '',
    makesByCountry: '',
    updatingConnectConfig: '',
    updatingAppLogo: '',
    brandManagementFilterOptions: '',
  },

  brandManagementFilterOptions: {
    engineType: {
      PHEV: false,
      BEV: false,
      ICE: false,
    },
    endpoints: {},
  },

  isFetchingApplicationMakes: true,
  isFetchingConnectConfig: true,
  isFetchingBrandManagementFilterOptions: true,
  isFetchingMakesByCountry: false,
  isUpdatingAppLogo: false,
  isUpdating: false,
  makesByCountry: [],
  selectedMakes: [],
  availableMakesAfterAppliedFilter: [],
  selectedCountries: [],
  selectedBrandManagementFilters: [],
  areAllBrandsDisabled: false,
  previewSettings: {
    selectedAppName: undefined,
    selectedTheme: 'light',
    selectedBranding: 'default',
    selectedLogo: '',
    selectedSortOrder: 'popularity',
    selectedNewBrandOptIn: 'enabled', // a boolean in smartcar-db
    selectedCountry: 'US',
  },
  currentTab: 0,
};

export const actions = {
  fetchSupportedCountriesRequest: () => ({
    type: types.FETCH_SUPPORTED_COUNTRIES_REQUEST,
  }),
  fetchSupportedCountriesSuccess: countries => ({
    type: types.FETCH_SUPPORTED_COUNTRIES_SUCCESS,
    payload: countries,
  }),
  fetchSupportedCountriesFailure: err => ({
    type: types.FETCH_SUPPORTED_COUNTRIES_FAILURE,
    payload: err,
  }),
  fetchConnectConfigRequest: () => ({
    type: types.FETCH_CONNECT_CONFIG_REQUEST,
  }),
  fetchConnectConfigSuccess: data => ({
    type: types.FETCH_CONNECT_CONFIG_SUCCESS,
    payload: data,
  }),
  fetchConnectConfigFailure: err => ({
    type: types.FETCH_CONNECT_CONFIG_FAILURE,
    payload: err,
  }),
  fetchBrandManagementFilterOptions: () => ({
    type: types.FETCH_BRAND_MANAGEMENT_FILTER_OPTIONS,
  }),
  fetchBrandManagementFilterOptionsSuccess: data => ({
    type: types.FETCH_BRAND_MANAGEMENT_FILTER_OPTIONS_SUCCESS,
    payload: data,
  }),
  fetchBrandManagementFilterOptionsFailure: err => ({
    type: types.FETCH_BRAND_MANAGEMENT_FILTER_OPTIONS_FAILURE,
    payload: err,
  }),
  fetchMakesByCountryRequest: country => ({
    type: types.FETCH_MAKES_BY_COUNTRY_REQUEST,
    payload: country,
  }),
  fetchMakesByCountrySuccess: data => ({
    type: types.FETCH_MAKES_BY_COUNTRY_SUCCESS,
    payload: data,
  }),
  fetchMakesByCountryFailure: err => ({
    type: types.FETCH_MAKES_BY_COUNTRY_FAILURE,
    payload: err,
  }),
  fetchApplicationMakesRequest: () => ({
    type: types.FETCH_APPLICATION_MAKES_REQUEST,
  }),
  fetchApplicationMakesSuccess: data => ({
    type: types.FETCH_APPLICATION_MAKES_SUCCESS,
    payload: data,
  }),
  fetchApplicationMakesFailure: err => ({
    type: types.FETCH_APPLICATION_MAKES_FAILURE,
    payload: err,
  }),
  updateSelectedMakes: data => ({
    type: types.UPDATE_SELECTED_MAKES,
    payload: data,
  }),
  updateAvailableMakesAfterAppliedFilter: data => ({
    type: types.UPDATE_AVAILABLE_MAKES_AFTER_APPLIED_FILTER,
    payload: data,
  }),
  updateSelectedCountries: data => ({
    type: types.UPDATE_SELECTED_COUNTRIES,
    payload: data,
  }),
  updateAppliedFilters: data => ({
    type: types.UPDATE_APPLIED_FILTERS,
    payload: data,
  }),
  disableAllBrands: areAllBrandsDisabled => ({
    type: types.DISABLE_ALL_BRANDS,
    payload: areAllBrandsDisabled,
  }),
  updatePreviewSettings: data => ({
    type: types.UPDATE_PREVIEW_SETTINGS,
    payload: data,
  }),
  clearError: errorKey => ({
    type: types.CLEAR_ERROR,
    payload: errorKey,
  }),
  updateConnectConfigRequest: data => ({
    type: types.UPDATE_CONNECT_CONFIG_REQUEST,
    payload: data,
  }),
  updateConnectConfigSuccess: data => ({
    type: types.UPDATE_CONNECT_CONFIG_SUCCESS,
    payload: data,
  }),
  updateConnectConfigFailure: err => ({
    type: types.UPDATE_CONNECT_CONFIG_FAILURE,
    payload: err,
  }),
  updateAppLogoRequest: (data, logoUrlToDelete) => ({
    type: types.UPDATE_APP_LOGO_REQUEST,
    payload: { data, logoUrlToDelete },
  }),
  updateAppLogoSuccess: data => ({
    type: types.UPDATE_APP_LOGO_SUCCESS,
    payload: data,
  }),
  updateAppLogoFailure: err => ({
    type: types.UPDATE_APP_LOGO_FAILURE,
    payload: err,
  }),
  deleteAppLogo: url => ({
    type: types.DELETE_APP_LOGO,
    payload: url,
  }),
  updateCurrentTab: data => ({
    type: types.UPDATE_CURRENT_TAB,
    payload: data,
  }),
};

export const selectors = {
  getConnectConfig: (state) => {
    return _.get(state, ['connectCustomizations', 'connectConfig']);
  },
  getPreviewSettings: (state) => {
    return _.get(state, ['connectCustomizations', 'previewSettings']);
  },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_SUPPORTED_COUNTRIES_REQUEST:
      return {
        ...state,
        fetchSupportedCountriesError: '',
      };
    case types.FETCH_SUPPORTED_COUNTRIES_SUCCESS:
      return {
        ...state,
        supportedCountries: action.payload,
      };
    case types.FETCH_SUPPORTED_COUNTRIES_FAILURE:
      return {
        ...state,
        fetchSupportedCountriesError: (action.payload && action.payload.message) || 'Error fetching supported countries',
      };
    case types.FETCH_CONNECT_CONFIG_REQUEST:
      return {
        ...state,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          connectConfig: '',
        },
        isFetchingConnectConfig: true,
      };
    case types.FETCH_CONNECT_CONFIG_SUCCESS:
      return {
        ...state,
        isFetchingConnectConfig: false,
        connectConfig: action.payload,
        selectedBrandManagementFilters:
          (action.payload && action.payload.brandManagementFilters) || [],
        previewSettings: {
          ...state.previewSettings,
          selectedAppName: appSelectors.getAppName(),
          selectedTheme: (action.payload && action.payload.theme) || 'light',
          selectedSortOrder: (action.payload && action.payload.supportedMakesSortOrder) || 'popularity',
          selectedNewBrandOptIn: (action.payload && action.payload.newBrandOptIn === false ? 'disabled' : 'enabled') || 'enabled',
          selectedBranding: (action.payload && action.payload.brandedHeader) ? 'custom' : 'default',
          selectedLogo: (action.payload && action.payload.logoUrl) || '',
        },
      };
    case types.FETCH_CONNECT_CONFIG_FAILURE:
      return {
        ...state,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          connectConfig:
            (action.payload && action.payload.message) || staticText.errors.connectConfig,
        },
        isFetchingConnectConfig: false,
      };
    case types.FETCH_BRAND_MANAGEMENT_FILTER_OPTIONS:
      return {
        ...state,
        isFetchingBrandManagementFilterOptions: true,
      };
    case types.FETCH_BRAND_MANAGEMENT_FILTER_OPTIONS_SUCCESS:
      return {
        ...state,
        brandManagementCapabilities: action.payload.brandManagementCapabilities,
        brandManagementFilterOptions: {
          ...state.brandManagementFilterOptions,
          endpoints: action.payload.endpoints,
        },
        isFetchingBrandManagementFilterOptions: false,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          brandManagementFilterOptions: '',
        },
      };
    case types.FETCH_BRAND_MANAGEMENT_FILTER_OPTIONS_FAILURE:
      return {
        ...state,
        isFetchingBrandManagementFilterOptions: false,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          brandManagementFilterOptions:
            (action.payload && action.payload.message) ||
            staticText.errors.brandManagementFilterOptions,
        },
      };
    case types.FETCH_APPLICATION_MAKES_REQUEST:
      return {
        ...state,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          applicationMakes: '',
        },
        isFetchingApplicationMakes: true,
      };
    case types.FETCH_APPLICATION_MAKES_SUCCESS:
      return {
        ...state,
        isFetchingApplicationMakes: false,
        applicationMakes: action.payload,
      };
    case types.FETCH_APPLICATION_MAKES_FAILURE:
      return {
        ...state,
        isFetchingApplicationMakes: false,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          applicationMakes:
            (action.payload && action.payload.message) || staticText.errors.applicationMakes,
        },
      };
    case types.UPDATE_SELECTED_MAKES:
      return {
        ...state,
        selectedMakes: action.payload,
      };
    case types.UPDATE_AVAILABLE_MAKES_AFTER_APPLIED_FILTER:
      return {
        ...state,
        availableMakesAfterAppliedFilter: action.payload,
      };
    case types.UPDATE_SELECTED_COUNTRIES:
      return {
        ...state,
        selectedCountries: action.payload,
      };
    case types.UPDATE_APPLIED_FILTERS:
      return {
        ...state,
        selectedBrandManagementFilters: action.payload,
      };
    case types.DISABLE_ALL_BRANDS:
      return {
        ...state,
        areAllBrandsDisabled: action.payload,
      };
    case types.UPDATE_PREVIEW_SETTINGS:
      return {
        ...state,
        previewSettings: {
          ...state.previewSettings,
          ...action.payload,
        },
      };
    case types.CLEAR_ERROR:
      return {
        ...state,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          [action.payload]: '',
        },
      };
    case types.UPDATE_CONNECT_CONFIG_REQUEST:
      return {
        ...state,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          updatingConnectConfig: '',
        },
        isFetchingConnectConfig: true,
        isUpdating: true,
      };
    case types.UPDATE_CONNECT_CONFIG_SUCCESS:
      return {
        ...state,
        connectConfig: {
          ...state.connectConfig,
          ...action.payload,
        },
        isFetchingConnectConfig: false,
        isUpdating: false,
      };
    case types.UPDATE_CONNECT_CONFIG_FAILURE:
      return {
        ...state,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          updatingConnectConfig:
            (action.payload && action.payload.message) || staticText.errors.updatingConnectConfig,
        },
        isFetchingConnectConfig: false,
        isUpdating: false,
      };
    case types.UPDATE_APP_LOGO_REQUEST:
      return {
        ...state,
        isUpdatingAppLogo: !!action.payload.data,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          updatingAppLogo: '',
        },
      };
    case types.UPDATE_APP_LOGO_SUCCESS:
      return {
        ...state,
        isUpdatingAppLogo: false,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          updatingAppLogo: '',
        },
        previewSettings: {
          ...state.previewSettings,
          selectedLogo: action.payload,
        },
      };
    case types.UPDATE_APP_LOGO_FAILURE:
      return {
        ...state,
        isUpdatingAppLogo: false,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          updatingAppLogo:
            staticText.errors.updatingAppLogo,
        },
      };
    case types.FETCH_MAKES_BY_COUNTRY_REQUEST:
      return {
        ...state,
        isFetchingMakesByCountry: true,
        previewSelectedCountry: action.payload,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          makesByCountry: '',
        },
      };
    case types.FETCH_MAKES_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        isFetchingMakesByCountry: false,
        makesByCountry: action.payload,
      };
    case types.FETCH_MAKES_BY_COUNTRY_FAILURE:
      return {
        ...state,
        isFetchingMakesByCountry: false,
        fetchingConnectConfigErrors: {
          ...state.fetchingConnectConfigErrors,
          makesByCountry:
            (action.payload && action.payload.message) || staticText.errors.makesByCountry,
        },
      };
    case types.UPDATE_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };
    default:
      return state;
  }
}
