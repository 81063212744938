const teamNav = [
  {
    text: 'settings',
  },
  {
    text: 'applications',
  },
  {
    text: 'members',
  },
  {
    text: 'billing',
  },
];

const userNav = [
  {
    text: 'profile',
  },
  // {
  //   text: 'security',
  // },
];

const applicationNav = [
  {
    heading: 'application',
    navLinks: [
      {
        text: 'overview',
      },
      {
        text: 'connect',
        subNav: [
          {
            text: 'customizations',
          },
          {
            text: 'insights',
            newfeature: true,
          },
        ],
      },
      {
        text: 'simulator',
      },
      {
        text: 'vehicles',
      },
      {
        text: 'logs',
      },
      {
        text: 'webhooks',
      },
      {
        text: 'configuration',
      },
    ],
  },
];

const settingsNav = [
  {
    heading: 'team',
    navLinks: teamNav,
  },
  {
    heading: 'user',
    navLinks: userNav,
  },

];

const topbarNav = {
  settings: {
    text: 'settings',
    path: '/team/settings',
    iconSrc: '/assets/icons/nav/settings.svg',
  },
  docs: {
    text: 'documentation',
    path: 'https://smartcar.com/docs',
    iconSrc: '/assets/icons/nav/book.svg',
  },
  support: {
    text: 'support',
    iconSrc: '/assets/icons/nav/support.svg',
  },
};

const accountMenu = {
  userProfile: {
    linkText: 'User profile',
    link: '/user/profile',
  },
  cookieSettings: 'Cookie settings',
  logout: 'Log out',
  privacy: {
    linkText: 'Privacy policy',
    link: 'https://smartcar.com/privacy',
  },
  terms: {
    linkText: 'Terms of service',
    link: 'https://smartcar.com/terms',
  },
};

export { topbarNav, applicationNav, settingsNav, accountMenu };
