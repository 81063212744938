import { Box, Button, Typography } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { InputField, Spinner, Toast } from '../../../../components';
import staticText from '../../../../localization/Authentication/updateUser';
import authFormState from '../../../Authentication/components/authFormUtils';
import { Section } from '../../styles';
import ApplicationHeader from '../ApplicationHeader';
import {
  EmailVerificationButton,
  EmailVerificationContainer,
  EmailVerificationError,
  UpdateUserForm,
} from './styles';

const UserProfile = ({
  actions: {
    resetErrors,
    sendVerificationEmailRequest,
    updateUserRequest,
  },
  selectedApp,
  inProgress,
  isSendingVerificationEmail,
  sendVerificationEmailError,
  session: {
    userContext,
  },
  updateUserFormErrors,
  updateUserSuccess,
  verificationEmailSent,
}) => {
  const [disableFields, setDisableFields] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const {
    setErroredFields,
    erroredFields,
    handleBlur,
    handleChange,
    values,
    getErrorMessage,
  } = authFormState({
    firstName: userContext.firstName,
    lastName: userContext.lastName,
    username: userContext.email,
  });

  useEffect(() => {
    resetErrors();
    reportToSegment(types.PAGE, 'Update User Modal');
  }, []);

  useEffect(() => {
    if (updateUserSuccess && formSubmitted) {
      Toast('Your update has been saved!', 'success');
      setFormSubmitted(false);
    }
  }, [updateUserSuccess]);

  useEffect(() => {
    setDisableFields(inProgress && !disableFields);
    setErroredFields(updateUserFormErrors);
  }, [inProgress, updateUserFormErrors]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      values.firstName !== userContext.firstName ||
      values.lastName !== userContext.lastName ||
      values.username !== userContext.email
    ) {
      updateUserRequest(values);
      reportToSegment(types.TRACK, eventNames.formSubmitted, {
        label: 'update user attempt',
        form_content: { values },
      });
      setFormSubmitted(true);
    }
  };

  const getGeneralError = () => {
    const error = erroredFields.find(err => !err.field || err.field === 'required_fields');
    return error ? error.message : '';
  };

  const handleEmailVerificationSend = (event) => {
    sendVerificationEmailRequest();
    reportToSegment(types.TRACK, eventNames.buttonClicked, {
      label: 'backend action',
      text: event.target.innerText,
    });
  };

  const getEmailVerificationContent = () => {
    let content = (
      <React.Fragment>
        {sendVerificationEmailError && (
          <EmailVerificationError
            id="verify-email-error-container"
            color="error"
            variant="caption"
          >
            {sendVerificationEmailError}
          </EmailVerificationError>
        )}
        <div>
          {verificationEmailSent
            ? staticText.emailVerificationSent
            : staticText.emailVerificationRequired
          }
        </div>
        <EmailVerificationButton
          id="verify-email-button"
          variant="text"
          onClick={handleEmailVerificationSend}
        >
          {verificationEmailSent
            ? staticText.resendVerificationButton
            : staticText.sendVerificationButton
          }
        </EmailVerificationButton>
      </React.Fragment>
    );
    if (isSendingVerificationEmail) {
      content = <Spinner size="small" />;
    }

    return (
      <EmailVerificationContainer>
        {content}
      </EmailVerificationContainer>
    );
  };

  return (
    <Box sx={{ maxWidth: '900px', minWidth: '650px' }}>
      <ApplicationHeader
        heading={staticText.title}
        backLink={selectedApp && selectedApp.id ? ({
          path: `/apps/${selectedApp.id}`,
          text: `Back to ${selectedApp.name} application`,
        }) : {}}
      />
      {!userContext.emailVerifiedAt && getEmailVerificationContent()}
      <Section>
        <UpdateUserForm onSubmit={handleSubmit}>
          <Typography id="error-container" color="error" variant="caption">{getGeneralError()}</Typography>
          <Box display="flex" flexWrap="wrap" justifyContent="space-between" mt={2}>
            {staticText.fields.map((field, index) => (
              <Box id={`input-${index}`} key={field.name} width={field.width || '100%'}>
                <InputField
                  inputName={field.name}
                  inputType={field.type}
                  inputValues={values[field.name]}
                  inputPlaceholder={field.placeholder}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  inputDisabled={
                    disableFields || (field.type === 'email' && userContext.loginProvider !== 'EmailPassword')
                  }
                  errorMessage={getErrorMessage(field)}
                />
              </Box>
            ))}
          </Box>
          <Box mt={4} mb={2} display="flex" justifyContent="space-between">
            <Button
              id="btnSubmit"
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              disabled={disableFields}
            >
              {staticText.btnSubmit}
            </Button>
          </Box>
        </UpdateUserForm>
      </Section>
    </Box>
  );
};

export default UserProfile;


UserProfile.propTypes = {
  actions: shape({
    updateUserRequest: func.isRequired,
  }).isRequired,
  isSendingVerificationEmail: bool.isRequired,
  inProgress: bool,
  sendVerificationEmailError: string.isRequired,
  selectedApp: shape({
    id: string,
    name: string,
  }).isRequired,
  session: shape({
    userContext: shape({
      firstName: string.isRequired,
      lastName: string.isRequired,
      email: string.isRequired,
      emailVerifiedAt: string,
    }).isRequired,
  }).isRequired,
  updateUserFormErrors: arrayOf(
    shape({
      field: string,
      message: string,
    }).isRequired,
  ),
  updateUserSuccess: bool.isRequired,
  verificationEmailSent: bool.isRequired,
};

UserProfile.defaultProps = {
  updateUserFormErrors: [],
  inProgress: false,
};

