/* eslint-disable react/forbid-prop-types */

import React, { useState, useEffect } from 'react';
import { string, number, func, shape, object, any, bool } from 'prop-types';
import { eventNames, reportToSegment, types } from '@smartcar/morse';
import _ from 'lodash';

import { Button } from '@mui/material';
import { filtersConfig, staticText } from '../../../../../../../../localization/Application/Logs/filtersBar';
import { DateRangePicker, MenuItemWrapper, FiltersForm } from '../../../../../../../../components';
import ExportButton from './components';
import { Form, ResetButton } from './styles';
import PermissionGate from '../../../../../../../../components/PermissionGate';
import alphabetizeMakes from '../../../../utils/alphabetizeMakes';
import getFilterStatusAndTitle from '../../../../utils/getFilterStatusAndTitle';

const FiltersBar = ({
  handleResetAll,
  filterValues,
  handleApplyFilters,
  logRestrictions,
  filtersEnabled,
}) => {
  const [newFormState, setNewFormState] = useState(filterValues);

  const { logDays, minDate, maxDate } = logRestrictions;

  const dateRange = [newFormState.start, newFormState.end];

  const getHandleChange = filterKey => (newValue) => {
    if (filterKey === 'dateRange') {
      setNewFormState({
        ...newFormState,
        start: newValue[0] ? newValue[0] : null,
        end: newValue[1] ? newValue[1] : null,
      });
    } else {
      setNewFormState({
        ...newFormState,
        [filterKey]: newValue,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // apply filter resets page to 0 so we need to reset offset to 0
    handleApplyFilters({ ...newFormState, offset: 0 });

    reportToSegment(
      types.TRACK,
      eventNames.formSubmitted,
      {
        label: '[request logs] filter values',
        form_content: newFormState,
      },
    );
  };

  const handleResetClick = () => {
    setNewFormState(filterValues);
    handleResetAll();
  };

  useEffect(() => {
    // because filterValues can be changed elsewhere (ie. Reset All Button)
    setNewFormState(filterValues);
  }, [filterValues]);

  return (
    <Form onSubmit={handleSubmit}>
      <ResetButton
        id="reset-all-button"
        variant="text"
        onClick={handleResetClick}
      >
        {staticText.resetAll}
      </ResetButton>
      <DateRangePicker
        darkerBorder
        label={staticText.conDate}
        onAccept={getHandleChange('dateRange')}
        value={dateRange}
        minDate={minDate}
        maxDate={maxDate}
        popupMessage={staticText.logDaysAllowance(logDays)}
      />
      {filtersEnabled &&
        filtersConfig.map(({
          filterKey, icon, formTitle, id, minWidth,
        }) => {
          const {
            isFilterActive,
            menuItemWrapperTitle,
          } = getFilterStatusAndTitle(newFormState, filterKey, formTitle);

          return (
            <MenuItemWrapper
              title={menuItemWrapperTitle}
              isFilterActive={isFilterActive}
              key={filterKey}
              Icon={icon}
              id={id}
              minWidth={minWidth}
              filterKey={filterKey}
            >
              <FiltersForm
                checkboxOptions={filterKey !== 'makes' ? newFormState[filterKey]
              : alphabetizeMakes(newFormState[filterKey])}
                updateCheckboxes={getHandleChange(filterKey)}
                title={formTitle}
              />
            </MenuItemWrapper>
          );
        })
      }
      <Button
        color="primary"
        type="submit"
        variant="contained"
        disabled={_.isEqual(filterValues, newFormState)}
      >
        {staticText.apply}
      </Button>
      <PermissionGate dashboardPermission="write_overview">
        <ExportButton>
          {staticText.export}
        </ExportButton>
      </PermissionGate>
    </Form>
  );
};


export default FiltersBar;

FiltersBar.propTypes = {
  filterValues: shape({
    makes: object.isRequired,
    statusCodes: object.isRequired,
    start: any.isRequired,
    end: any.isRequired,
    offset: number.isRequired,
    limit: number.isRequired,
  }).isRequired,
  handleResetAll: func.isRequired,
  handleApplyFilters: func.isRequired,
  logRestrictions: shape({
    minDate: string.isRequired,
    maxDate: string.isRequired,
    logDays: number.isRequired,
  }).isRequired,
  filtersEnabled: bool.isRequired,
};
