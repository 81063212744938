import _ from 'lodash';
import { errors } from '../../localization/Setup/account';

export const types = {
  SET_ACCOUNT_TYPE: 'developer-client/SET_ACCOUNT_TYPE',

  CREATE_DASHBOARD_USER_REQUEST: 'developer-client/CREATE_DASHBOARD_USER_REQUEST',
  CREATE_DASHBOARD_USER_SUCCESS: 'developer-client/CREATE_DASHBOARD_USER_SUCCESS',
  CREATE_DASHBOARD_USER_FAILURE: 'developer-client/CREATE_DASHBOARD_USER_FAILURE',

  DELETE_DASHBOARD_USER_REQUEST: 'developer-client/DELETE_DASHBOARD_USER_REQUEST',
  DELETE_DASHBOARD_USER_SUCCESS: 'developer-client/DELETE_DASHBOARD_USER_SUCCESS',
  DELETE_DASHBOARD_USER_FAILURE: 'developer-client/DELETE_DASHBOARD_USER_FAILURE',

  AUTHENTICATE_DEVELOPER_REQUEST: 'developer-client/AUTHENTICATE_DEVELOPER_REQUEST',
  AUTHENTICATE_DEVELOPER_SUCCESS: 'developer-client/AUTHENTICATE_DEVELOPER_SUCCESS',
  AUTHENTICATE_DEVELOPER_FAILURE: 'developer-client/AUTHENTICATE_DEVELOPER_FAILURE',

  AUTHENTICATE_OATH_REQUEST: 'developer-client/AUTHENTICATE_OATH_REQUEST',
  AUTHENTICATE_OATH_SUCCESS: 'developer-client/AUTHENTICATE_OATH_SUCCESS',
  AUTHENTICATE_OATH_FAILURE: 'developer-client/AUTHENTICATE_OATH_FAILURE',

  CREATE_OATH_REQUEST: 'developer-client/CREATE_OATH_REQUEST',
  CREATE_OATH_SUCCESS: 'developer-client/CREATE_OATH_SUCCESS',
  CREATE_OATH_FAILURE: 'developer-client/CREATE_OATH_FAILURE',

  GET_LOGIN_PROVIDER_REQUEST: 'developer-client/GET_LOGIN_PROVIDER_REQUEST',
  GET_LOGIN_PROVIDER_SUCCESS: 'developer-client/GET_LOGIN_PROVIDER_SUCCESS',
  GET_LOGIN_PROVIDER_FAILURE: 'developer-client/GET_LOGIN_PROVIDER_FAILURE',

  GET_DEVELOPER_REQUEST: 'developer-client/GET_DEVELOPER_REQUEST',
  GET_DEVELOPER_SUCCESS: 'developer-client/GET_DEVELOPER_SUCCESS',
  GET_DEVELOPER_FAILURE: 'developer-client/GET_DEVELOPER_FAILURE',

  FORGOT_PASSWORD_REQUEST: 'developer-client/FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'developer-client/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'developer-client/FORGOT_PASSWORD_FAILURE',

  RESET_PASSWORD_REQUEST: 'developer-client/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'developer-client/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'developer-client/RESET_PASSWORD_FAILURE',

  LOGOUT_REQUEST: 'developer-client/LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'developer-client/LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'developer-client/LOGOUT_FAILURE',

  SEND_VERIFICATION_EMAIL_REQUEST: 'developer-client/SEND_VERIFICATION_EMAIL_REQUEST',
  SEND_VERIFICATION_EMAIL_SUCCESS: 'developer-client/SEND_VERIFICATION_EMAIL_SUCCESS',
  SEND_VERIFICATION_EMAIL_FAILURE: 'developer-client/SEND_VERIFICATION_EMAIL_FAILURE',
  SET_VERIFICATION_EMAIL_SENT: 'developer-client/SET_VERIFICATION_EMAIL_SENT',

  VERIFY_EMAIL_TOKEN_REQUEST: 'developer-client/VERIFY_EMAIL_TOKEN_REQUEST',
  VERIFY_EMAIL_TOKEN_SUCCESS: 'developer-client/VERIFY_EMAIL_TOKEN_SUCCESS',
  VERIFY_EMAIL_TOKEN_FAILURE: 'developer-client/VERIFY_EMAIL_TOKEN_FAILURE',
  SET_EMAIL_TOKEN: 'developer-client/SET_EMAIL_TOKEN',

  UPDATE_USER_REQUEST: 'developer-client/auth/UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'developer-client/auth/UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'developer-client/auth/UPDATE_USER_FAILURE',

  RESET_ERRORS: 'developer-client/auth/RESET_ERRORS',
  SET_PLAN_REDIRECT: 'developer-client/SET_PLAN_REDIRECT',
  SET_POST_AUTH_REDIRECT: 'developer-client/SET_POST_AUTH_REDIRECT',
  RESET_OAUTH_INVITATION: 'developer-client/RESET_OAUTH_INVITATION',

  SET_IS_NEW_SIGN_UP: 'developer-client/SET_IS_NEW_SIGN_UP',

  TRIGGER_OAUTH_FLOW_REQUEST: 'developer-client/TRIGGER_OAUTH_FLOW_REQUEST',
  TRIGGER_OAUTH_FLOW_SUCCESS: 'developer-client/TRIGGER_OAUTH_FLOW_SUCCESS',
  TRIGGER_OAUTH_FLOW_FAILURE: 'developer-client/TRIGGER_OAUTH_FLOW_FAILURE',

  RESET_OAUTH_FLOW: 'developer-client/RESET_OAUTH_FLOW',
  SET_OAUTH_FLOW_INVITATION_ID: 'developer-client/SET_OAUTH_FLOW_INVITATION_ID',
  SET_PREFILLED_INVITED_USERNAME: 'developer-client/SET_PREFILLED_INVITED_USERNAME',
  RESET_LOGIN_PAGE: 'developer-client/RESET_LOGIN_PAGE',

  MFA_ENROLL_REQUEST: 'developer-client/MFA_ENROLL_REQUEST',
  MFA_ENROLL_SUCCESS: 'developer-client/MFA_ENROLL_SUCCESS',
  MFA_ENROLL_FAILURE: 'developer-client/MFA_ENROLL_FAILURE',
  MFA_LOGIN_RECOVERY_SUCCESS: 'developer-client/MFA_LOGIN_RECOVERY_SUCCESS',
  MFA_LOGIN_SUCCESS: 'developer-client/MFA_LOGIN_SUCCESS',
  MFA_LOGIN_FAILURE: 'developer-client/MFA_LOGIN_FAILURE',
  MFA_LOGIN_VERIFICATION_REQUEST: 'developer-client/MFA_LOGIN_VERIFICATION_REQUEST',
  MFA_LOGIN_VERIFICATION_SUCCESS: 'developer-client/MFA_LOGIN_VERIFICATION_SUCCESS',
  MFA_LOGIN_VERIFICATION_FAILURE: 'developer-client/MFA_LOGIN_VERIFICATION_FAILURE',
  MFA_NEW_CHALLENGE_REQUEST: 'developer-client/MFA_NEW_CHALLENGE_REQUEST',
  MFA_NEW_CHALLENGE_SUCCESS: 'developer-client/MFA_NEW_CHALLENGE_SUCCESS',
  MFA_NEW_CHALLENGE_FAILURE: 'developer-client/MFA_NEW_CHALLENGE_FAILURE',
  MFA_DISABLE_REQUEST: 'developer-client/MFA_DISABLE_REQUEST',
  MFA_DISABLE_SUCCESS: 'developer-client/MFA_DISABLE_SUCCESS',
  MFA_DISABLE_FAILURE: 'developer-client/MFA_DISABLE_FAILURE',
  MFA_VERIFICATION_REQUEST: 'developer-client/MFA_VERIFICATION_REQUEST',
  MFA_VERIFICATION_SUCCESS: 'developer-client/MFA_VERIFICATION_SUCCESS',
  MFA_VERIFICATION_FAILURE: 'developer-client/MFA_VERIFICATION_FAILURE',
  MFA_RECOVERY_REQUEST: 'developer-client/MFA_RECOVERY_REQUEST',
  MFA_RECOVERY_SUCCESS: 'developer-client/MFA_RECOVERY_SUCCESS',
  MFA_RECOVERY_FAILURE: 'developer-client/MFA_RECOVERY_FAILURE',
  MFA_RESET_LOGIN: 'developer-client/MFA_RESET_LOGIN',
  MFA_VERIFY_RECOVERY_TOKEN_REQUEST: 'developer-client/MFA_VERIFY_RECOVERY_TOKEN_REQUEST',
  MFA_VERIFY_RECOVERY_TOKEN_SUCCESS: 'developer-client/MFA_VERIFY_RECOVERY_TOKEN_SUCCESS',
  MFA_VERIFY_RECOVERY_TOKEN_FAILURE: 'developer-client/MFA_VERIFY_RECOVERY_TOKEN_FAILURE',
  MFA_SET_RECOVERY_TOKEN: 'developer-client/MFA_SET_RECOVERY_TOKEN',
};

export const initialState = {
  accountType: '',
  loginProvider: 'GoogleOAuth',
  emailToken: '',
  oauthFlowInvitationId: '',
  prefilledInvitedUserName: null,
  loginProviderUsername: null,
  automaticallyLoginViaOauth: false,
  triggerOauthFlow: false,
  authFlow: null,
  authorizationUrl: '',
  forgotPasswordSuccess: false,
  formSuccess: false,
  isLoggedIn: false,
  isNewSignUp: false,
  planRedirect: '',
  postAuthRedirect: '',
  session: {
    accessToken: null,
    userContext: null,
  },
  updateUserSuccess: false,
  verificationEmailSent: false,
  mfaObj: {
    factorId: null,
    challengeId: null,
    qrCode: null,
    secretKey: null,
  },
  mfaEnabled: false,
  mfaRequiredForLogin: false,
  mfaChallengeSuccess: false,
  mfaRecoveryEmailSent: false,
  mfaRecoveryToken: '',

  // loading states
  isFetchingDeveloper: false,
  isLoggingOut: false,
  isDeletingUser: false,
  inProgress: false,
  isSendingVerificationEmail: false,
  isVerifyingEmailToken: false,
  mfaInProgress: false,

  // errors
  authErrorMessage: '',
  deleteUserError: '',
  forgotPasswordFormErrors: [],
  loginFormErrors: [],
  resetPasswordFormErrors: [],
  sendVerificationEmailError: '',
  signUpFormErrors: [],
  updateUserFormErrors: [],
  verifyEmailTokenError: '',
  mfaError: '',
};

export const actions = {
  resetErrors: () => ({
    type: types.RESET_ERRORS,
    payload: null,
  }),
  resetOauthInvitation: () => ({
    type: types.RESET_OAUTH_INVITATION,
  }),
  setAccountType: type => ({
    type: types.SET_ACCOUNT_TYPE,
    payload: type,
  }),

  createDashboardUser: fields => ({
    type: types.CREATE_DASHBOARD_USER_REQUEST,
    payload: fields,
  }),
  createDashboardUserSuccess: user => ({
    type: types.CREATE_DASHBOARD_USER_SUCCESS,
    payload: user,
  }),
  createDashboardUserFailure: error => ({
    type: types.CREATE_DASHBOARD_USER_FAILURE,
    payload: error,
  }),

  deleteDashboardUser: () => ({
    type: types.DELETE_DASHBOARD_USER_REQUEST,
    payload: null,
  }),
  deleteDashboardUserSuccess: () => ({
    type: types.DELETE_DASHBOARD_USER_SUCCESS,
    payload: null,
  }),
  deleteDashboardUserFailure: error => ({
    type: types.DELETE_DASHBOARD_USER_FAILURE,
    payload: error,
  }),

  authenticateDeveloperRequest: hash => ({
    type: types.AUTHENTICATE_DEVELOPER_REQUEST,
    payload: hash,
  }),
  authenticateDeveloperSuccess: authParams => ({
    type: types.AUTHENTICATE_DEVELOPER_SUCCESS,
    payload: { ...authParams },
  }),
  authenticateDeveloperFailure: error => ({
    type: types.AUTHENTICATE_DEVELOPER_FAILURE,
    payload: error,
  }),

  authenticateOAuthRequest: user => ({
    type: types.AUTHENTICATE_OATH_REQUEST,
    payload: user,
  }),
  authenticateOAuthSuccess: user => ({
    type: types.AUTHENTICATE_OATH_SUCCESS,
    payload: user,
  }),
  authenticateOAuthFailure: error => ({
    type: types.AUTHENTICATE_OATH_FAILURE,
    payload: error,
  }),

  createOauthRequest: user => ({
    type: types.CREATE_OATH_REQUEST,
    payload: user,
  }),
  createOauthSuccess: () => ({
    type: types.CREATE_OATH_SUCCESS,
  }),
  createOauthFailure: error => ({
    type: types.CREATE_OATH_FAILURE,
    payload: error,
  }),

  getLoginProviderRequest: username => ({
    type: types.GET_LOGIN_PROVIDER_REQUEST,
    payload: username,
  }),

  getLoginProviderSuccess: data => ({
    type: types.GET_LOGIN_PROVIDER_SUCCESS,
    payload: data,
  }),

  getLoginProviderFailure: error => ({
    type: types.GET_LOGIN_PROVIDER_FAILURE,
    payload: error,
  }),

  getDeveloperRequest: () => ({
    type: types.GET_DEVELOPER_REQUEST,
    payload: null,
  }),
  getDeveloperSuccess: developer => ({
    type: types.GET_DEVELOPER_SUCCESS,
    payload: developer,
  }),
  getDeveloperFailure: error => ({
    type: types.GET_DEVELOPER_FAILURE,
    payload: error,
  }),

  forgotPasswordRequest: username => ({
    type: types.FORGOT_PASSWORD_REQUEST,
    payload: username,
  }),
  forgotPasswordSuccess: () => ({
    type: types.FORGOT_PASSWORD_SUCCESS,
    payload: null,
  }),
  forgotPasswordFailure: error => ({
    type: types.FORGOT_PASSWORD_FAILURE,
    payload: error,
  }),

  resetPasswordRequest: (fields, authToken) => ({
    type: types.RESET_PASSWORD_REQUEST,
    payload: { fields, authToken },
  }),
  resetPasswordSuccess: () => ({
    type: types.RESET_PASSWORD_SUCCESS,
    payload: null,
  }),
  resetPasswordFailure: error => ({
    type: types.RESET_PASSWORD_FAILURE,
    payload: error,
  }),

  logoutRequest: redirect => ({
    type: types.LOGOUT_REQUEST,
    payload: redirect,
  }),
  logoutSuccess: () => ({
    type: types.LOGOUT_SUCCESS,
    payload: null,
  }),
  logoutFailure: error => ({
    type: types.LOGOUT_FAILURE,
    payload: error,
  }),

  sendVerificationEmailRequest: () => ({
    type: types.SEND_VERIFICATION_EMAIL_REQUEST,
    payload: null,
  }),
  sendVerificationEmailSuccess: () => ({
    type: types.SEND_VERIFICATION_EMAIL_SUCCESS,
    payload: null,
  }),
  sendVerificationEmailFailure: error => ({
    type: types.SEND_VERIFICATION_EMAIL_FAILURE,
    payload: error,
  }),
  setVerificationEmailSent: emailSent => ({
    type: types.SET_VERIFICATION_EMAIL_SENT,
    payload: emailSent,
  }),

  verifyEmailTokenRequest: emailToken => ({
    type: types.VERIFY_EMAIL_TOKEN_REQUEST,
    payload: emailToken,
  }),
  verifyEmailTokenSuccess: () => ({
    type: types.VERIFY_EMAIL_TOKEN_SUCCESS,
    payload: null,
  }),
  verifyEmailTokenFailure: error => ({
    type: types.VERIFY_EMAIL_TOKEN_FAILURE,
    payload: error,
  }),
  setEmailToken: emailToken => ({
    type: types.SET_EMAIL_TOKEN,
    payload: emailToken,
  }),

  updateUserRequest: developerChanges => ({
    type: types.UPDATE_USER_REQUEST,
    payload: developerChanges,
  }),
  updateUserSuccess: developer => ({
    type: types.UPDATE_USER_SUCCESS,
    payload: developer,
  }),
  updateUserFailure: error => ({
    type: types.UPDATE_USER_FAILURE,
    payload: error,
  }),

  setPlanRedirect: planRedirect => ({
    type: types.SET_PLAN_REDIRECT,
    payload: planRedirect,
  }),
  setPostAuthRedirect: postAuthRedirect => ({
    type: types.SET_POST_AUTH_REDIRECT,
    payload: postAuthRedirect,
  }),

  // OAuth
  triggerOauthFlowRequest: data => ({
    type: types.TRIGGER_OAUTH_FLOW_REQUEST,
    payload: data,
  }),
  triggerOauthFlowSuccess: data => ({
    type: types.TRIGGER_OAUTH_FLOW_SUCCESS,
    payload: data,
  }),
  triggerOauthFlowFailure: error => ({
    type: types.TRIGGER_OAUTH_FLOW_FAILURE,
    payload: error,
  }),

  // MFA
  mfaEnrollRequest: () => ({
    type: types.MFA_ENROLL_REQUEST,
    payload: null,
  }),
  mfaEnrollSuccess: data => ({
    type: types.MFA_ENROLL_SUCCESS,
    payload: data,
  }),
  mfaEnrollFailure: error => ({
    type: types.MFA_ENROLL_FAILURE,
    payload: error,
  }),
  mfaLoginRecoverySuccess: data => ({
    type: types.MFA_LOGIN_RECOVERY_SUCCESS,
    payload: data,
  }),
  mfaLoginSuccess: data => ({
    type: types.MFA_LOGIN_SUCCESS,
    payload: data,
  }),
  mfaLoginFailure: data => ({
    type: types.MFA_LOGIN_FAILURE,
    payload: data,
  }),
  mfaLoginVerificationRequest: data => ({
    type: types.MFA_LOGIN_VERIFICATION_REQUEST,
    payload: data,
  }),
  mfaLoginVerificationSuccess: () => ({
    type: types.MFA_LOGIN_VERIFICATION_SUCCESS,
    payload: null,
  }),
  mfaLoginVerificationFailure: error => ({
    type: types.MFA_LOGIN_VERIFICATION_FAILURE,
    payload: error,
  }),
  mfaNewChallengeRequest: () => ({
    type: types.MFA_NEW_CHALLENGE_REQUEST,
    payload: null,
  }),
  mfaNewChallengeSuccess: data => ({
    type: types.MFA_NEW_CHALLENGE_SUCCESS,
    payload: data,
  }),
  mfaNewChallengeFailure: error => ({
    type: types.MFA_NEW_CHALLENGE_FAILURE,
    payload: error,
  }),
  mfaDisableRequest: () => ({
    type: types.MFA_DISABLE_REQUEST,
    payload: null,
  }),
  mfaDisableSuccess: data => ({
    type: types.MFA_DISABLE_SUCCESS,
    payload: data,
  }),
  mfaDisableFailure: error => ({
    type: types.MFA_DISABLE_FAILURE,
    payload: error,
  }),
  mfaVerificationRequest: data => ({
    type: types.MFA_VERIFICATION_REQUEST,
    payload: data,
  }),
  mfaVerificationSuccess: data => ({
    type: types.MFA_VERIFICATION_SUCCESS,
    payload: data,
  }),
  mfaVerificationFailure: error => ({
    type: types.MFA_VERIFICATION_FAILURE,
    payload: error,
  }),
  mfaRecoveryRequest: data => ({
    type: types.MFA_RECOVERY_REQUEST,
    payload: data,
  }),
  mfaRecoverySuccess: () => ({
    type: types.MFA_RECOVERY_SUCCESS,
    payload: null,
  }),
  mfaRecoveryFailure: error => ({
    type: types.MFA_RECOVERY_FAILURE,
    payload: error,
  }),
  mfaResetLogin: () => ({
    type: types.MFA_RESET_LOGIN,
  }),
  mfaSetRecoveryToken: mfaRecoveryToken => ({
    type: types.MFA_SET_RECOVERY_TOKEN,
    payload: mfaRecoveryToken,
  }),

  setIsNewSignUp: isNewSignUp => ({
    type: types.SET_IS_NEW_SIGN_UP,
    payload: isNewSignUp,
  }),
  resetOauthFlow: () => ({
    type: types.RESET_OAUTH_FLOW,
  }),
  setOauthFlowInvitationId: oauthFlowInvitationId => ({
    type: types.SET_OAUTH_FLOW_INVITATION_ID,
    payload: oauthFlowInvitationId,
  }),
  setPrefilledInvitedUsername: prefilledInvitedUserName => ({
    type: types.SET_PREFILLED_INVITED_USERNAME,
    payload: prefilledInvitedUserName,
  }),
  resetLoginPage: () => ({
    type: types.RESET_LOGIN_PAGE,
  }),
};

export const selectors = {
  getAccessToken: (state) => {
    return _.get(state, ['auth', 'session', 'accessToken']);
  },
  getAccountType: (state) => {
    return _.get(state, ['auth', 'accountType']);
  },
  getEmailToken: (state) => {
    return _.get(state, ['auth', 'emailToken']);
  },
  getPlanRedirect: (state) => {
    return _.get(state, ['auth', 'planRedirect']);
  },
  getPostAuthRedirect: (state) => {
    return _.get(state, ['auth', 'postAuthRedirect']);
  },
  getSession: (state) => {
    return _.get(state, ['auth', 'session']);
  },
  getUserContext: (state) => {
    return _.get(state, ['auth', 'session', 'userContext']);
  },
  getIsNewSignUp: (state) => {
    return _.get(state, ['auth', 'isNewSignUp']);
  },
  getOauthFlowInvitationId: (state) => {
    return _.get(state, ['auth', 'oauthFlowInvitationId']);
  },
  getLoginProvider: (state) => {
    return _.get(state, ['auth', 'loginProvider']);
  },
  getPrefilledInvitedUserName: (state) => {
    return _.get(state, ['auth', 'prefilledInvitedUserName']);
  },
  getMfaRequiredForLogin: (state) => {
    return _.get(state, ['auth', 'mfaRequiredForLogin']);
  },
  getMfaRecoveryToken: (state) => {
    return _.get(state, ['auth', 'mfaRecoveryToken']);
  },
};

export function getErrorMessage(action) {
  if (action.payload) {
    const { detail = [], message = [] } = _.get(action.payload.response, 'data');
    if (detail && detail.message) {
      return detail.message;
    }
    if (!Array.isArray(message) && message) {
      return message;
    }
    if (message && message.length) {
      return message;
    }
  }

  return [];
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.RESET_ERRORS:
      return {
        ...state,
        signUpFormErrors: [],
        loginFormErrors: [],
        updateUserFormErrors: [],
        forgotPasswordFormErrors: [],
        resetPasswordFormErrors: [],
      };
    case types.RESET_OAUTH_INVITATION:
      return {
        ...state,
        authorizationUrl: '',
        oauthFlowInvitationId: null,
        prefilledInvitedUserName: null,
      };
    case types.SET_ACCOUNT_TYPE:
      return {
        ...state,
        accountType: action.payload,
      };

    case types.CREATE_DASHBOARD_USER_REQUEST:
      return {
        ...state,
        inProgress: true,
        loginProvider: 'EmailPassword',
        signUpFormErrors: [],
      };
    case types.CREATE_DASHBOARD_USER_SUCCESS:
      return {
        ...state,
        inProgress: false,
        prefilledInvitedUserName: null,
        signUpFormErrors: [],
      };
    case types.CREATE_DASHBOARD_USER_FAILURE:
      return {
        ...state,
        inProgress: false,
        prefilledInvitedUserName: null,
        signUpFormErrors: getErrorMessage(action),
      };

    case types.DELETE_DASHBOARD_USER_REQUEST:
      return {
        ...state,
        isDeletingUser: true,
        deleteUserError: '',
      };
    case types.DELETE_DASHBOARD_USER_SUCCESS:
      return {
        ...state,
        isDeletingUser: false,
      };
    case types.DELETE_DASHBOARD_USER_FAILURE:
      return {
        ...state,
        isDeletingUser: false,
        deleteUserError: (
          action.payload
          && action.payload.response
          && action.payload.response.data
          && action.payload.response.data.message
        ) || errors.accountDeletion,
      };

    case types.AUTHENTICATE_DEVELOPER_REQUEST:
      return {
        ...state,
        inProgress: true,
        loginFormErrors: [],
      };
    case types.AUTHENTICATE_DEVELOPER_SUCCESS:
      return {
        ...state,
        inProgress: false,
        prefilledInvitedUserName: null,
        loginFormErrors: [],
      };
    case types.AUTHENTICATE_DEVELOPER_FAILURE:
      return {
        ...state,
        inProgress: false,
        prefilledInvitedUserName: null,
        loginFormErrors: getErrorMessage(action),
      };

    case types.AUTHENTICATE_OATH_REQUEST:
      return {
        ...state,
        inProgress: true,
        loginFormErrors: [],
      };
    case types.AUTHENTICATE_OATH_SUCCESS:
      return {
        ...state,
        inProgress: false,
        prefilledInvitedUserName: null,
        loginFormErrors: [],
      };
    case types.AUTHENTICATE_OATH_FAILURE:
      return {
        ...state,
        inProgress: false,
        prefilledInvitedUserName: null,
        loginFormErrors: getErrorMessage(action),
      };

    case types.CREATE_OATH_REQUEST:
      return {
        ...state,
        inProgress: true,
        signUpFormErrors: [],
      };
    case types.CREATE_OATH_SUCCESS:
      return {
        ...state,
        inProgress: false,
        prefilledInvitedUserName: null,
        signUpFormErrors: [],
      };
    case types.CREATE_OATH_FAILURE:
      return {
        ...state,
        inProgress: false,
        prefilledInvitedUserName: null,
        signUpFormErrors: getErrorMessage(action),
      };

    case types.GET_LOGIN_PROVIDER_REQUEST:
      return {
        ...state,
        inProgress: true,
        mfaObj: {
          factorId: null,
        },
        mfaRequiredForLogin: false,
        automaticallyLoginViaOauth: false,
        loginProviderUsername: null,
        loginFormErrors: [],
      };

    case types.GET_LOGIN_PROVIDER_SUCCESS:
      return {
        ...state,
        inProgress: false,
        loginProvider: action.payload.loginProvider,
        mfaObj: action.payload.mfa,
        mfaRequiredForLogin: action.payload.mfaRequiredForLogin,
        loginProviderUsername: action.payload.loginProviderUsername,
        automaticallyLoginViaOauth: action.payload.automaticallyLoginViaOauth,
        loginFormErrors: [],
      };

    case types.GET_LOGIN_PROVIDER_FAILURE:
      return {
        ...state,
        inProgress: false,
        loginProvider: 'GoogleOAuth',
        loginFormErrors: getErrorMessage(action),
      };

    case types.GET_DEVELOPER_REQUEST: {
      return {
        ...state,
        isFetchingDeveloper: true,
        developerError: '',
      };
    }
    case types.GET_DEVELOPER_SUCCESS: {
      return {
        ...state,
        session: {
          ...state.session,
          userContext: action.payload.data,
        },
        oauthFlowInvitationId: '',
        isFetchingDeveloper: false,
        isLoggedIn: true,
        mfaEnabled: !!action.payload.data.mfaFactorId,
      };
    }
    case types.GET_DEVELOPER_FAILURE: {
      return {
        ...state,
        isFetchingDeveloper: false,
        oauthFlowInvitationId: '',
        developerError: (action.payload && action.payload.message) || 'Error fetching developer',
      };
    }

    case types.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        inProgress: true,
        formSuccess: false,
        forgotPasswordSuccess: false,
        forgotPasswordFormErrors: [],
      };
    case types.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        inProgress: false,
        formSuccess: true,
        forgotPasswordSuccess: true,
      };
    case types.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        inProgress: false,
        formSuccess: false,
        forgotPasswordSuccess: false,
        forgotPasswordFormErrors: getErrorMessage(action),
      };

    case types.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        inProgress: true,
        formSuccess: false,
        resetPasswordFormErrors: [],
      };
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        inProgress: false,
        formSuccess: true,
      };
    case types.RESET_PASSWORD_FAILURE:
      return {
        ...state,
        inProgress: false,
        formSuccess: false,
        resetPasswordFormErrors: getErrorMessage(action),
      };

    case types.LOGOUT_REQUEST:
      return {
        ...state,
        isLoggingOut: true,
        authErrorMessage: '',
      };
    case types.LOGOUT_SUCCESS:
      return {
        ...initialState,
      };
    case types.LOGOUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
        authErrorMessage: (action.payload && action.payload.message) || 'Error logging out',
      };

    case types.SEND_VERIFICATION_EMAIL_REQUEST:
      return {
        ...state,
        verificationEmailSent: false,
        isSendingVerificationEmail: true,
        sendVerificationEmailError: '',
        verifyEmailTokenError: '',
      };
    case types.SEND_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        isSendingVerificationEmail: false,
        verificationEmailSent: true,
      };
    case types.SEND_VERIFICATION_EMAIL_FAILURE:
      return {
        ...state,
        isSendingVerificationEmail: false,
        sendVerificationEmailError: (
          action.payload
          && action.payload.response
          && action.payload.response.data
          && action.payload.response.data.message
        ) || 'Error sending verification email',
      };
    case types.SET_VERIFICATION_EMAIL_SENT:
      return {
        ...state,
        verificationEmailSent: action.payload,
      };

    case types.VERIFY_EMAIL_TOKEN_REQUEST:
      return {
        ...state,
        isVerifyingEmailToken: true,
        verifyEmailTokenError: '',
      };
    case types.VERIFY_EMAIL_TOKEN_SUCCESS:
      return {
        ...state,
        isVerifyingEmailToken: false,
        emailToken: '',
      };
    case types.VERIFY_EMAIL_TOKEN_FAILURE:
      return {
        ...state,
        isVerifyingEmailToken: false,
        verifyEmailTokenError: (
          action.payload
          && action.payload.response
          && action.payload.response.data
          && action.payload.response.data.message
        ) || 'Error in verifying email',
        emailToken: '',
      };
    case types.SET_EMAIL_TOKEN:
      return {
        ...state,
        emailToken: action.payload,
      };

    case types.UPDATE_USER_REQUEST:
      return {
        ...state,
        inProgress: true,
        updateUserSuccess: false,
        updateUserFormErrors: [],
      };
    case types.UPDATE_USER_SUCCESS:
      return {
        ...state,
        session: {
          ...state.session,
          userContext: {
            ...state.session.userContext,
            email: action.payload.developer.username,
            firstName: action.payload.developer.firstName,
            lastName: action.payload.developer.lastName,
          },
        },
        updateUserSuccess: true,
        inProgress: false,
      };
    case types.UPDATE_USER_FAILURE:
      return {
        ...state,
        inProgress: false,
        updateUserSuccess: false,
        updateUserFormErrors: getErrorMessage(action),
      };

    case types.SET_PLAN_REDIRECT:
      return {
        ...state,
        planRedirect: action.payload,
      };
    case types.SET_POST_AUTH_REDIRECT:
      return {
        ...state,
        postAuthRedirect: action.payload,
      };
    case types.SET_IS_NEW_SIGN_UP:
      return {
        ...state,
        isNewSignUp: action.payload,
      };

    case types.MFA_ENROLL_REQUEST:
      return {
        ...state,
        mfaObj: {
          factorId: null,
          challengeId: null,
          qrCode: null,
          secretKey: null,
        },
        mfaInProgress: true,
        mfaError: '',
      };
    case types.MFA_ENROLL_SUCCESS:
      return {
        ...state,
        mfaObj: action.payload,
        mfaInProgress: false,
        mfaError: '',
      };
    case types.MFA_ENROLL_FAILURE:
      return {
        ...state,
        mfaObj: {
          factorId: null,
          challengeId: null,
          qrCode: null,
          secretKey: null,
        },
        mfaInProgress: false,
        mfaError: getErrorMessage(action),
      };
    case types.MFA_LOGIN_RECOVERY_SUCCESS:
      return {
        ...state,
        mfaObj: {
          factorId: action.payload.factorId,
          challengeId: action.payload.challengeId,
          qrCode: action.payload.qrCode,
          secretKey: action.payload.secretKey,
        },
        mfaInProgress: false,
        mfaError: '',
      };
    case types.MFA_LOGIN_SUCCESS:
      return {
        ...state,
        mfaObj: {
          ...state.mfaObj,
          challengeId: action.payload.challengeId,
        },
        session: {
          ...state.session,
          userContext: {
            ...state.session.userContext,
            dashboardUserId: action.payload.dashboardUserId,
          },
        },
        mfaInProgress: false,
        mfaError: '',
      };
    case types.MFA_LOGIN_FAILURE:
      return {
        ...state,
        mfaObj: {
          factorId: null,
          challengeId: null,
          qrCode: null,
          secretKey: null,
        },
        mfaInProgress: false,
        mfaError: getErrorMessage(action),
      };
    case types.MFA_LOGIN_VERIFICATION_REQUEST:
      return {
        ...state,
        mfaInProgress: true,
        mfaError: '',
      };
    case types.MFA_LOGIN_VERIFICATION_SUCCESS:
      return {
        ...state,
        mfaRequiredForLogin: false,
        mfaEnabled: true,
        mfaInProgress: false,
        mfaError: '',
        inProgress: false,
        prefilledInvitedUserName: null,
        loginFormErrors: [],
        mfaRecoveryToken: '',
      };
    case types.MFA_LOGIN_VERIFICATION_FAILURE:
      return {
        ...state,
        mfaInProgress: false,
        mfaError: getErrorMessage(action),
      };

    case types.MFA_NEW_CHALLENGE_REQUEST:
      return {
        ...state,
        mfaInProgress: true,
        mfaError: '',
      };
    case types.MFA_NEW_CHALLENGE_SUCCESS:
      return {
        ...state,
        mfaObj: action.payload,
        mfaInProgress: false,
        mfaError: '',
      };
    case types.MFA_NEW_CHALLENGE_FAILURE:
      return {
        ...state,
        mfaInProgress: false,
        mfaError: getErrorMessage(action),
      };
    case types.MFA_DISABLE_REQUEST:
      return {
        ...state,
        mfaInProgress: true,
        mfaError: '',
      };
    case types.MFA_DISABLE_SUCCESS:
      return {
        ...state,
        mfaEnabled: false,
        mfaObj: {
          factorId: null,
          challengeId: null,
          qrCode: null,
          secretKey: null,
        },
        mfaInProgress: false,
        mfaError: '',
      };
    case types.MFA_DISABLE_FAILURE:
      return {
        ...state,
        mfaInProgress: false,
        mfaError: getErrorMessage(action),
      };
    case types.MFA_VERIFICATION_REQUEST:
      return {
        ...state,
        mfaInProgress: true,
        mfaError: '',
      };
    case types.MFA_VERIFICATION_SUCCESS:
      return {
        ...state,
        mfaRequiredForLogin: !action.payload,
        mfaEnabled: action.payload,
        mfaInProgress: false,
        mfaError: '',
      };
    case types.MFA_VERIFICATION_FAILURE:
      return {
        ...state,
        mfaInProgress: false,
        mfaError: getErrorMessage(action),
      };
    case types.MFA_RECOVERY_REQUEST:
      return {
        ...state,
        mfaRecoveryEmailSent: false,
        mfaInProgress: true,
        mfaError: '',
      };
    case types.MFA_RECOVERY_SUCCESS:
      return {
        ...state,
        mfaObj: {
          factorId: null,
          challengeId: null,
          qrCode: null,
          secretKey: null,
        },
        mfaEnabled: false,
        mfaRecoveryEmailSent: true,
        mfaRequiredForLogin: false,
        mfaInProgress: false,
        mfaError: '',
      };
    case types.MFA_RECOVERY_FAILURE:
      return {
        ...state,
        mfaInProgress: false,
        mfaError: getErrorMessage(action),
      };
    case types.MFA_RESET_LOGIN:
      return {
        ...state,
        loginProvider: 'GoogleOAuth',
        inProgress: false,
        mfaObj: {
          factorId: null,
          challengeId: null,
          qrCode: null,
          secretKey: null,
        },
        mfaEnabled: false,
        mfaRequiredForLogin: false,
        mfaChallengeSuccess: false,
        mfaRecoveryEmailSent: false,
      };
    case types.MFA_SET_RECOVERY_TOKEN:
      return {
        ...state,
        mfaRecoveryToken: action.payload,
      };

    case types.TRIGGER_OAUTH_FLOW_REQUEST:
      return {
        ...state,
        triggerOauthFlow: true,
        automaticallyLoginViaOauth: false,
        authFlow: action.payload.authFlow,
        authorizationUrl: '',
      };
    case types.TRIGGER_OAUTH_FLOW_SUCCESS:
      return {
        ...state,
        authorizationUrl: action.payload,
      };
    case types.TRIGGER_OAUTH_FLOW_FAILURE:
      return {
        ...state,
        authorizationUrl: '',
        loginFormErrors: getErrorMessage(action),
      };
    case types.RESET_OAUTH_FLOW:
      return {
        ...state,
        triggerOauthFlow: false,
        authFlow: null,
        authorizationUrl: '',
        automaticallyLoginViaOauth: null,
      };
    case types.SET_OAUTH_FLOW_INVITATION_ID:
      return {
        ...state,
        oauthFlowInvitationId: action.payload,
      };
    case types.SET_PREFILLED_INVITED_USERNAME:
      return {
        ...state,
        prefilledInvitedUserName: action.payload,
      };
    case types.RESET_LOGIN_PAGE:
      return {
        ...state,
        loginProvider: 'GoogleOAuth',
      };
    default:
      return state;
  }
}
