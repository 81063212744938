import React, { useEffect, useState } from 'react';
import { bool, element, func, shape, string } from 'prop-types';
import { reportToSegment, types, eventNames } from '@smartcar/morse';
import { Box, Button, Typography, Tooltip, Stepper, Step, StepButton, useTheme } from '@mui/material';
import { withRouter } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CheckCircleTwoTone, CircleOutlined, CircleTwoTone } from '@mui/icons-material';

import { InlineLinks, Spinner } from '../../../../../../components';
import { modalConfig } from '../../utils/webhookModalHelpers';

import { alertTriangleIcon } from '../../../../../../assets/icons';
import formFields from './fields';
import fieldValidations from './validators';
import FormField from './FormField';
import useFormState from './useFormState';
import Review from './Review';
import { filterAndFormatReviewFields } from './Review/utils';
import useStepper from './useStepper';
import { Form, StepConnector, StepLabel } from './styles';

// eslint-disable-next-line react/prop-types
const StepIcon = ({ active, completed }) => {
  if (completed) return <CheckCircleTwoTone color="primary" />;
  if (active) return <CircleTwoTone color="primary" />;
  return <CircleOutlined color="disabled" />;
};

const staticText = {
  navigation: {
    next: 'Next',
    back: 'Back',
  },
  interested: "I'm interested",
  interestRecorded: "Your interest has been recorded. We'll notify you when Dynamic webhooks becomes available.",
  errorDescription: 'Please correct one or more fields above',
};

const {
  fields, defaultFields, sections, sectionNames,
} = formFields;
const { validators } = fieldValidations;

const WebhooksForm = ({
  actions: {
    createWebhookRequest, createWebhookInterestRequest, updateWebhookRequest, toggleWebhookModal,
  },
  webhook,
  isCreatingWebhook,
  isSubmittingInterest,
  webhooksErrorMessage,
  mode,
  upgradeCta,
  upgradeMessage,
}) => {
  const { dynamicWebhooksEnabled } = useFlags();
  const modalName = mode;
  const {
    clearErroredField,
    erroredFields,
    handleInputBlur,
    handleChange,
    values,
  } = useFormState(webhook);
  const [flag, setFlag] = useState(false);
  const theme = useTheme();

  const submittedWebhookInterest = Boolean(localStorage.getItem('webhookInterest'));

  const runValidators = (selectFields) => {
    return Object.keys(validators)
      .filter(field => selectFields.includes(field))
      .reduce((acc, key) => {
        const isKeyValid = validators[key](values[key]);
        if (erroredFields[key] && isKeyValid) {
          clearErroredField(key);
        }
        return acc && isKeyValid;
      }, true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (webhook.id) {
      updateWebhookRequest(webhook.id, values);
      toggleWebhookModal({ nextModal: null, curModal: modalName, trackCloseEvent: false });
    } else {
      createWebhookRequest(values);
    }

    reportToSegment(types.TRACK, eventNames.formSubmitted, {
      label: `${webhook.id ? 'edit' : 'add'} webhook`,
      form_content: { values },
    });
  };

  const filteredFields = fields.filter(({ showFieldCondition }) => {
    return !showFieldCondition || showFieldCondition.includes(values.type);
  });

  const {
    currentSection,
    handleStep,
    activeStep,
    isLastStep,
    showBackButton,
    handleBack,
    handleNext,
    fieldsBySection,
  } = useStepper({ filteredFields, sections, defaultStep: webhook.id ? 1 : 0 });

  const sectionIsValid = runValidators(fieldsBySection.map(field => field.name));
  const formValidByType = type => type && runValidators(defaultFields[type]);

  const handleDynamicWebhookInterest = async () => {
    createWebhookInterestRequest({ feature: 'dynamic_webhooks', label: staticText.interested });
  };

  const renderPrimaryCta = () => {
    if (values.type === 'dynamic' && !dynamicWebhooksEnabled) {
      const interestedButton = (
        <Button disabled={submittedWebhookInterest || isSubmittingInterest} onClick={handleDynamicWebhookInterest} size="large" variant="contained">
          {staticText.interested}
        </Button>
      );
      return (submittedWebhookInterest ?
        <Tooltip placement="top" arrow title={staticText.interestRecorded} PopperProps={{ sx: { zIndex: 2147483647 } }}>
          <span>
            {interestedButton}
          </span>
        </Tooltip>
        : interestedButton
      );
    } else if (isLastStep) {
      return (
        <React.Fragment>
          {upgradeCta ||
            <Button
              id="submit-create-webhook"
              variant="contained"
              disabled={!formValidByType(values.type) || !!upgradeMessage}
              size="large"
              type="submit"
              sx={{ padding: '13px 26px' }}
            >
              {isCreatingWebhook ? (
                <Spinner size="button" spinnerColor="#FFF" additionalClassNames="simulator-vin-form" />
                  ) : modalConfig[modalName].buttonText}
            </Button>
          }
        </React.Fragment>
      );
    }
    return (
      <Button onClick={handleNext} size="large" variant="contained" disabled={!sectionIsValid} sx={{ padding: '13px 26px' }}>
        {staticText.navigation.next}
      </Button>
    );
  };

  useEffect(() => {
    if (flag && !isCreatingWebhook && !webhooksErrorMessage) {
      toggleWebhookModal({ nextModal: 'verify', curModal: modalName, trackCloseEvent: false });
    }
    if (isCreatingWebhook && !flag) {
      setFlag(true);
    }
  }, [isCreatingWebhook]);

  return (
    <Form onSubmit={handleSubmit} >
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<StepConnector />}
        sx={{ margin: theme.spacing(0, 6) }}
      >
        {sections.map((label, index) => (
          <Step key={label}>
            {(index === 0 || index === sections.length - 1) && <StepConnector className={index === 0 ? 'first-connector' : 'last-connector'} />}
            <StepButton onClick={handleStep(index)} >
              <StepLabel
                StepIconComponent={StepIcon}
                active={activeStep === index}
              >
                {label}
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Box mt={4.5}>
        {currentSection === sectionNames.REVIEW
          ? <Review fields={filterAndFormatReviewFields(filteredFields, values)} />
          : fieldsBySection.map(
          ({
            label,
            name,
            type,
            placeholder,
            errorMessage,
            children,
            labelTooltip,
            labelSubtext,
            disabledMode,
            text,
            note,
          }) => (
            <div
              key={name}
              className="m-b-lg"
            >
              <label htmlFor={name}>
                <span className="technical-bold grey m-r-med">{label.toUpperCase()}</span>
                {labelTooltip && labelTooltip.mode.includes(mode) && (
                  <Tooltip title={labelTooltip.text} PopperProps={{ sx: { zIndex: 2147483647 } }}>
                    <img src={labelTooltip.iconSrc} alt={labelTooltip.alt} />
                  </Tooltip>
                )}
                {labelSubtext && (
                  <span className="label-subtext">
                    {labelSubtext.type === 'text' ? (
                      labelSubtext.text
                    ) : (
                      <InlineLinks text={labelSubtext.text} />
                    )}
                  </span>
                )}
              </label>
              <div className="m-t-med">
                <FormField
                  inputName={name}
                  inputType={type}
                  inputValues={values[name]}
                  inputPlaceholder={placeholder}
                  handleChange={handleChange}
                  handleBlur={handleInputBlur}
                  inputText={text}
                  inputNote={note}
                  errorMessage={erroredFields[name] ? errorMessage : ''}
                  disabled={disabledMode && disabledMode.includes(mode)}
                >
                  {children}
                </FormField>
              </div>
            </div>
          ),
        )}
      </Box>
      <div>
        {Object.keys(erroredFields).length > 0 && (
        <Box display="flex" alignItems="center" marginBottom={1}>
          <img src={alertTriangleIcon} alt="error" />
          <Typography
            variant="caption"
            component="p"
            color="error"
            marginLeft={1}
          >
            {staticText.errorDescription}
          </Typography>
        </Box>
        )}
      </div>
      {isLastStep && upgradeMessage}
      <Box gap={2} sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2 }}>
        {showBackButton && (
          <Button onClick={handleBack}>
            {staticText.navigation.back}
          </Button>
        )}
        {renderPrimaryCta()}
      </Box>
    </Form>
  );
};

export default withRouter(WebhooksForm);

WebhooksForm.propTypes = {
  actions: shape({
    createWebhookRequest: func.isRequired,
    updateWebhookRequest: func.isRequired,
    toggleWebhookModal: func.isRequired,
  }).isRequired,
  webhook: shape({}),
  isCreatingWebhook: bool.isRequired,
  isSubmittingInterest: bool.isRequired,
  webhooksErrorMessage: string.isRequired,
  mode: string.isRequired,
  upgradeCta: element,
  upgradeMessage: element,
};

WebhooksForm.defaultProps = {
  webhook: {},
  upgradeCta: null,
  upgradeMessage: null,
};
