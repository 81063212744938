import React, { useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { array, arrayOf, bool, func, objectOf, shape, string } from 'prop-types';
import { Button, FormControl, InputLabel, MenuItem } from '@mui/material';
import { eventNames, reportToSegment, types } from '@smartcar/morse';

import { modeOptions, selectFilters, staticText } from '../../../../../../../../localization/Application/VehicleManagement/filtersBar';
import { Form, ResetButton, Select } from '../../../shared/styles';
import { TextField } from '../../../../../../../../global-styles/components';
import { initialState } from '../../../../reducers';
import validateFilterValues from '../../../../utils/validateFilterValues';
import { DateRangePicker } from '../../../../../../../../components';
import { alphabetize } from '../../../../../../utils';

const FiltersBar = ({
  compatibleVehicles,
  filterValues,
  handleApplyFilters,
  handleResetAll,
  setFormError,
  filtersEnabled,
}) => {
  const [newFormState, setNewFormState] = useState(filterValues);

  const defaultFilterValues = initialState.filterValues;

  useEffect(() => {
    // reset models whenever make is updated
    setNewFormState({
      ...newFormState,
      model: defaultFilterValues.model,
    });
  }, [newFormState.make]);

  useEffect(() => {
    // because filterValues can be changed elsewhere
    setNewFormState(filterValues);
  }, [filterValues]);

  const getHandleChange = filterKey => (e) => {
    let newValue;
    if (filterKey === 'conDateRange') {
      newValue = e;
    } else {
      newValue = e.target.value;
    }
    setFormError('');
    setNewFormState({
      ...newFormState,
      [filterKey]: newValue,
      ...(filterKey === 'make' && {
        model: defaultFilterValues.model,
      }),
    });
  };

  const getYears = () => {
    const yearRange = [];
    for (let year = 2010; year <= new Date().getFullYear() + 1; year += 1) {
      yearRange.push(year);
    }
    return yearRange;
  };

  const getMenuOptions = (filterKey) => {
    if (filterKey === 'mode') {
      return modeOptions;
    } else if (filterKey === 'make') {
      const makeOptions = Object.keys(compatibleVehicles).map((make) => {
        return {
          value: compatibleVehicles[make].make,
          displayName: make,
        };
      });
      makeOptions.sort((a, b) => alphabetize(a.displayName, b.displayName));
      return [
        { value: defaultFilterValues.make, displayName: 'None' },
        ...makeOptions,
      ];
    } else if (filterKey === 'model') {
      if (newFormState.make === defaultFilterValues.make
        || Object.keys(compatibleVehicles).length === 0) {
        return [{ value: defaultFilterValues.model, displayName: 'None' }];
      }
      const modelOptions =
        Object.values(compatibleVehicles)
          .find(vehicle => vehicle.make === newFormState.make)
          .models
          .map((model) => {
            return {
              value: model,
              displayName: model,
            };
          });
      return [{ value: defaultFilterValues.model, displayName: 'None' }, ...modelOptions];
    }

    const yearOptions = getYears().map((year) => {
      return { value: year, displayName: year };
    });
    return [{ value: defaultFilterValues.year, displayName: 'None' }, ...yearOptions];
  };

  const isDisabled = (filterKey) => {
    if (filterKey === 'model') {
      return newFormState.make === defaultFilterValues.make;
    }
    return false;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleApplyFilters(newFormState);
    reportToSegment(
      types.TRACK,
      eventNames.formSubmitted,
      {
        label: '[vehicles] filter values',
        form_content: newFormState,
      },
    );
  };

  const handleResetClick = () => {
    setFormError('');
    setNewFormState(filterValues);
    handleResetAll();
    reportToSegment(
      types.TRACK,
      eventNames.buttonClicked,
      {
        label: 'reset',
        text: `[vehicles] ${staticText.resetAll}`,
      },
    );
  };

  return (
    <Form
      onSubmit={handleSubmit}
    >
      <ResetButton
        id="reset-all-button"
        onClick={handleResetClick}
        variant="text"
      >
        {staticText.resetAll}
      </ResetButton>
      <DateRangePicker
        label={staticText.conDate}
        onChange={getHandleChange('conDateRange')}
        value={newFormState.conDateRange}
      />
      {filtersEnabled &&
        <React.Fragment>
          <TextField
            id="vehicle-id"
            label={staticText.vehicleId}
            onChange={getHandleChange('vehicleId')}
            value={newFormState.vehicleId}
            variant="outlined"
            sx={{ width: '200px' }}
            invalid={Boolean(validateFilterValues({
              vehicleId: newFormState.vehicleId,
            }).error)}
          />
          {selectFilters.map(({
            filterKey, icon, id, minWidth, label,
          }) => {
            const labelId = `${label}-select-label`;
            return (
              <FormControl
                disabled={isDisabled(filterKey)}
                key={id}
                size="small"
                variant="outlined"
              >
                <InputLabel id={labelId} >{label}</InputLabel>
                <Select
                  IconComponent={icon}
                  id={id}
                  label={label}
                  labelId={labelId}
                  name={filterKey}
                  onChange={getHandleChange(filterKey)}
                  value={newFormState[filterKey]}
                  sx={{ minWidth }}
                >
                  {getMenuOptions(filterKey)
                    .map(({ value, displayName }) => (
                      <MenuItem
                        key={displayName}
                        value={value}
                      >
                        {value ? displayName : <em>{displayName}</em>}
                      </MenuItem>),
                    )
                    }
                </Select>
              </FormControl>
            );
          })}
        </React.Fragment>
      }
      <Button
        color="primary"
        disabled={isEqual(newFormState, filterValues)}
        type="submit"
        variant="contained"
      >
        {staticText.apply}
      </Button>
    </Form>
  );
};

export default FiltersBar;

FiltersBar.propTypes = {
  compatibleVehicles: objectOf(
    shape({
      make: string.isRequired,
      models: arrayOf(string).isRequired,
    }),
  ).isRequired,
  filterValues: shape({
    make: string.isRequired,
    model: string.isRequired,
    vehicleId: string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    conDateRange: array.isRequired,
  }).isRequired,
  handleApplyFilters: func.isRequired,
  handleResetAll: func.isRequired,
  setFormError: func.isRequired,
  filtersEnabled: bool.isRequired,
};
